import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  vendorUsers: {},
};

export const VendorUserSlice = createSlice({
  name: "vendorUser",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.vendorUsers = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = VendorUserSlice.actions;

// A selector
export const getVendorUserSelector = (state) => state.vendorUser;

// The reducer
export default VendorUserSlice.reducer;

// api call action
export const getVendorUsers =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(getApp());
    return makeAPICall({
      path: "/auth/list/vendors/",
      params,
      method: "GET",
    })
      .then((res) => {
        dispatch(getAppSuccess(res));
      })
      .catch((err) => {
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getAppFailure(err));
      });
  };
