export const accordionData = [
    {
        number: "01",
        heading: "Who can answer my technical questions relating to my account?",
        content:
            "For  all relating questions, please contact the following  number, +2348022717162, +2348168399532, +2349024631063, +2348062964776", 
    },
    {
        number: "02",
        heading: "Who is qualified to be an ecommerce account holder?",
        content:
            "An individual / business owner  that has the capability of shippping above  50 packages a week.",
    },
    {
        number: "03",
        heading: "What can I ship?",
        content:
            "We ship  all kinds of items( light and heavy).",
    },
    {
        number: "04",
        heading: "What warranties do I have on my shipments?",
        content:
            "Our goods in transit/ goods stored  is duly insured by Royal Exchange insurance.",
    },
];