import React, { useState } from "react";
import { Link } from "react-router-dom";
import Background from "../../../assets/images/auth-bg/bg-1.jpg";
import eye from "../../../assets/images/eye-24-512.png";
import eyeHidden from "../../../assets/images/invisible 2.png";
import { useSelector, useDispatch } from "react-redux";
import {
  getLoginSelector,
  loginUser,
} from "../../../services/slices/auth/login";
import AioLogo from "../../../assets/images/aio-img.png";

export default function Login() {
  const dispatch = useDispatch();
  const { loading } = useSelector(getLoginSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState("password");
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
    setPasswordField(passwordField === "text" ? "password" : "text");
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = () => {
    const data = {
      email: inputValues.email,
      password: inputValues.password,
    };
    dispatch(loginUser(data));
  }

  return (
    <div
      className="hold-transition theme-primary bg-img"
      style={{
        backgroundImage: `url(${Background})`,
        height: "100vh",
      }}
    >
      <div className="container h-p100">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <a className="navbar-brand" href="/">
                      <img src={AioLogo} className=" nav-logo" alt="" />
                    </a>
                    {/* <h2 className="text-primary">Let's Get Started</h2> */}
                    <p className="mb-0">Sign in</p>
                  </div>
                  <div className="p-40">
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent">
                          <i className="ti-user"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control ps-15 bg-transparent"
                          placeholder="Email"
                          value={inputValues.email}
                          onChange={handleChangeInput}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text  bg-transparent">
                          <i className="ti-lock"></i>
                        </span>
                        <input
                          type={passwordField}
                          className="form-control ps-15 bg-transparent"
                          placeholder="Password"
                          value={inputValues.password}
                          onChange={handleChangeInput}
                          name="password"
                        />
                         {showPassword ? (
                        <img
                          src={eye}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible img-size"
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible"
                        />
                      )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="checkbox">
                          <input type="checkbox" id="basic_checkbox_1" />
                          <label htmlFor="basic_checkbox_1">Remember Me</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fog-pwd text-end">
                          <Link className="hover-warning" to="/forgot-password">
                            <i className="ion ion-locked"></i> Forgot pwd?
                          </Link>
                          <br />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button disabled={!(inputValues.password && inputValues.email)} type="submit" className="btn btn-danger mt-10" onClick={handleSubmit}>
                          {loading ? "Loading..." : "SIGN IN"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="mt-15 mb-0">
                        Don't have an account?{" "}
                        <Link to="/signup" className="text-warning ms-5">
                          Sign Up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
