// src/services/slices/index.js
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import loginReducer from "./auth/login";
import registerAdminReducer from "./auth/adminRegister";
import userReducer from "./admin/users";
import vendorUserReducer from "./admin/vendor";
import pricingReducer from "./admin/pricing";
import orderReducer from "./admin/orders";
import orderSummaryReducer from "./admin/orderSummary";
import SummaryReducer from "./admin/summary";
import ridersAccountReducer from "./admin/createRiders";
import riderReducer from "./admin/riders";
import scheduleReducer from "./admin/schedules";
import transactionReducer from "./admin/transactions";
import dispatcherReducer from "./dispatcher";
import registerReducer from "./auth/register";
import WalletBalanceReducer from "./vendor/balance";
import orderVendorReducer from "./vendor/orders";
import transactionVendorReducer from "./vendor/transactions";
import pricingVendorReducer from "./vendor/pricing";
import allSchedulesReducer from "./rider/allSchedules";
import activeSchedulesReducer from "./rider/activeSchedules";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "counter",
  storage,
};

const rootReducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  user: userReducer,
  vendorUser: vendorUserReducer,
  transaction: transactionReducer,
  pricing: pricingReducer,
  order: orderReducer,
  schedule: scheduleReducer,
  rider: riderReducer,
  ridersAccount: ridersAccountReducer,
  pricingList: dispatcherReducer,
  balance: WalletBalanceReducer,
  orderVendor: orderVendorReducer,
  transactionVendor: transactionVendorReducer,
  pricingVendor: pricingVendorReducer,
  allSchedule: allSchedulesReducer,
  activeSchedule: activeSchedulesReducer,
  registerAdmin: registerAdminReducer,
  orderSummary: orderSummaryReducer,
  summary: SummaryReducer,
});

const PersistedReducer = persistReducer(persistConfig, rootReducer);

export default PersistedReducer;
