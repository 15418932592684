import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import "./contact.css";

import ContactImg from "../../assets/images/contactPix.png";

const Contact = () => {
  return (
    <>
      <section className="contact-one">
        <Header />
        <div className="contactHero-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Contact us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="contactRoundedBorder">
          <div className="container">
            <div className="row">
              <div className="contact-box">
                <p>Contact Us</p>
                <small>Home / Contact Us</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="contact-two">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>Feel Free to Knock Us</h3>
              <p>
              If you have any questions or need additional information, do not hesitate to contact us. We have an unbeatable support system so you can contact us via help desk or email 24/7.
              </p>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-md-7 mb-3">
              <form action="" className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        // className="form-control"
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <input
                        type="email"
                        // className="form-control"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <input
                        type="text"
                        // className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <textarea
                        name="message"
                        rows="5"
                        // className="form-control"
                        required=""
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="contact-twoBtn mt-4"
                    >
                      {" "}
                      Contact Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5 mb-3">
              <img src={ContactImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9 trans">
              <h3>Are you looking for a Transport Service?</h3>
              <p>We always help you to transport your products and documents</p>
            </div>
            <div className="col-md-3">
              <button className="contact-threeBtn">Send Request</button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
