import React, { useState, useEffect } from "react";
import BackBone from "../../../components/BackBone";
import { useSelector, useDispatch } from "react-redux";
import {
  getWalletBalanceSelector,
  getWalletBalance,
} from "../../../services/slices/vendor/balance";
import {
  getOrderVendorSelector,
  getOrdersVendor,
} from "../../../services/slices/vendor/orders";
import { getLoginSelector } from "../../../services/slices/auth/login";
import {
  getTransactionVendorSelector,
  getTransactionsVendor,
} from "../../../services/slices/vendor/transactions";
import { getPricingVendor } from "../../../services/slices/vendor/pricing";
import { formatMoney } from "../../../utils/helperFunctions";
import ModalComponent from "../../../components/Modal";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

export default function VendorDash() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const trxref = searchParams.get("trxref"); // "testCode"
  const { loading, balance } = useSelector(getWalletBalanceSelector);
  const { loading: loadOrder, ordersVendor } = useSelector(
    getOrderVendorSelector
  );
  const { user } = useSelector(getLoginSelector);
  const { loading: loadTransac, transactionsVendor } = useSelector(
    getTransactionVendorSelector
  );
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [unpaidOrders, setUnpaidOrders] = useState();

  useEffect(() => {
    const confirmPayment = (token) => {
      return makeAPICall({
        path: `/wallet/deposit/verify/${token}`,
        method: "GET",
      })
        .then((res) => {
          dispatch(getWalletBalance());
          toast(res?.data?.status, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    };
    if (trxref) {
      confirmPayment(trxref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trxref]);

  useEffect(() => {
    const handleOrders = () => {
      setIsLoadingOrder(true);
      return makeAPICall({
        path: "/shipping/unpaid/order/list/",
        method: "GET",
      })
        .then((res) => {
          setUnpaidOrders(res);
          setIsLoadingOrder(false);
        })
        .catch((err) => {
          setIsLoadingOrder(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    };
    handleOrders();
    dispatch(getWalletBalance());
    dispatch(getOrdersVendor({}));
    dispatch(getTransactionsVendor({}));
    dispatch(getPricingVendor({}));
  }, [dispatch]);

  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      amount: amount,
      email: user?.email,
    };
    return makeAPICall({
      path: "/wallet/deposit/",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setIsLoading(false);
        handleCloseModal();
        // dispatch(getWalletBalance());
        window.location.href = res?.data?.authorization_url;
        setAmount("");
        toast("Please complete payment", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <BackBone title="Overview">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          {/* button to create order */}
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              Fund Wallet
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loading ? (
                      "Loading..."
                    ) : (
                      <span>₦{formatMoney(balance)}</span>
                    )}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-money"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Wallet Balance</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadOrder ? "Loading..." : ordersVendor?.count}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Orders</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {isLoadingOrder ? "Loading..." : unpaidOrders?.count}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Unpaid orders</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadTransac ? "Loading..." : transactionsVendor?.count}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-exchange"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Transactions</p>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent title="Fund Wallet">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Enter Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g 1500"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      name="amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!amount}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Save
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </BackBone>
  );
}
