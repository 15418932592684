import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  allSchedules: {},
};

export const allSchedulesSlice = createSlice({
  name: "allSchedule",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.allSchedules = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = allSchedulesSlice.actions;

// A selector
export const getAllSchedulesSelector = (state) => state.allSchedule;

// The reducer
export default allSchedulesSlice.reducer;

// api call action
export const getAllSchedules =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(getApp());
    return makeAPICall({
      path: "/rider/list/all/schedules/",
      params,
      method: "GET",
    })
      .then((res) => {
        dispatch(getAppSuccess(res));
      })
      .catch((err) => {
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getAppFailure(err));
      });
  };
