import React, { useState } from "react";
import Background from "../../../assets/images/auth-bg/bg-1.jpg";
import AioLogo from "../../../assets/images/aio-img.png";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [inputValues, setInputValues] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      email: inputValues.email,
      callbackUrl: "aio.ng",
    };
    return makeAPICall({
      path: "/auth/request-reset-email/",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        setIsLoading(false);
        toast("Please check your email for a password reset link", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div
      className="hold-transition theme-primary bg-img"
      style={{
        backgroundImage: `url(${Background})`,
        height: "100vh",
      }}
    >
      <div className="container h-p100">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <a className="navbar-brand" href="/">
                      <img src={AioLogo} className=" nav-logo" alt="" />
                    </a>
                    {/* <h2 className="text-primary">Let's Get Started</h2> */}
                    <p className="mb-0">Recover Password</p>
                  </div>
                  <div className="p-40">
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent">
                          <i className="ti-user"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control ps-15 bg-transparent"
                          placeholder="Email"
                          value={inputValues.email}
                          onChange={handleChangeInput}
                          name="email"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          disabled={!inputValues.email}
                          type="submit"
                          className="btn btn-danger mt-10"
                          onClick={handleSubmit}
                        >
                          {isLoading ? "Loading..." : "Reset"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
