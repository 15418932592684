import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Background from "../../../assets/images/auth-bg/bg-2.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  getRegisterSelector,
  registerUser,
} from "../../../services/slices/auth/register";
import ReactPhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import logo from "../../../assets/images/aio-img.png";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, register } = useSelector(getRegisterSelector);
  const [phoneState, setPhoneState] = useState("");
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    password: "",
    passwordTwo: "",
  });
  const [lastView, setLastView] = useState(false);

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function signUp() {
    if (inputValues.password === inputValues.passwordTwo) {
      const plus = "+";
      const phoneNumber = plus.concat(phoneState);
      const data = {
        email: inputValues.email,
        password: inputValues.password,
        firstname: inputValues.first_name,
        lastname: inputValues.last_name,
        address: inputValues.address,
        phone: phoneNumber,
        is_business: "False",
        quantity: "4",
      };
      dispatch(registerUser(data));
    } else {
      toast("Password doesn't match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  useEffect(() => {
    if (
      register &&
      Object.keys(register).length === 0 &&
      Object.getPrototypeOf(register) === Object.prototype
    ) {
      setLastView(false);
    } else {
      setLastView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const gotoLogin = () => {
    navigate("/");
    window.localStorage.removeItem("persist:counter");
  };

  return (
    <div
      className="hold-transition theme-primary bg-img"
      style={{
        backgroundImage: `url(${Background})`,
        height: "100vh",
      }}
    >
      <div className="container h-p100">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <a className="navbar-brand" href="/">
                      <img src={logo} className=" nav-logo" alt="" />
                    </a>
                    <p className="mb-0">Register a new membership</p>
                  </div>
                  {lastView ? (
                    <div className="p-40">
                      <div className="text-center">
                        <img
                          src={logo}
                          alt="AIO Logo"
                          className="logo mb-3"
                          style={{ width: "200px" }}
                        />
                        <h1 className="congrats mb-3">Congratulations </h1>
                        <p className="congrats-details mb-5">
                          Please verify your email
                        </p>
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={gotoLogin}
                        >
                          Back to Home Page
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-40">
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control ps-15 bg-transparent"
                            placeholder="First Name"
                            value={inputValues.first_name}
                            onChange={handleChangeInput}
                            name="first_name"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Last Name"
                            value={inputValues.last_name}
                            onChange={handleChangeInput}
                            name="last_name"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Email"
                            value={inputValues.email}
                            onChange={handleChangeInput}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Address"
                            value={inputValues.address}
                            onChange={handleChangeInput}
                            name="address"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <ReactPhoneInput
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                            className: "formInput",
                          }}
                          value={phoneState}
                          onChange={handleOnChangePhoneInput}
                          country="ng"
                          placeholder="080212345678"
                          autoFormat={false}
                          enableClickOutside={true}
                        />
                      </div>

                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Password"
                            value={inputValues.password}
                            onChange={handleChangeInput}
                            name="password"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Retype Password"
                            value={inputValues.passwordTwo}
                            onChange={handleChangeInput}
                            name="passwordTwo"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="checkbox">
                            <input type="checkbox" id="basic_checkbox_1" />
                            <label htmlFor="basic_checkbox_1">
                              I agree to the{" "}
                              <Link className="text-warning">
                                <b>Terms</b>
                              </Link>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-info margin-top-10"
                            onClick={signUp}
                            disabled={
                              !(
                                inputValues.password &&
                                inputValues.passwordTwo &&
                                inputValues.email &&
                                inputValues.first_name &&
                                inputValues.last_name &&
                                phoneState &&
                                inputValues.address
                              )
                            }
                          >
                            {loading ? "Loading" : "REGISTER"}
                          </button>
                        </div>
                      </div>
                      <div className="text-center">
                        <p className="mt-15 mb-0">
                          Already have an account?
                          <Link to="/login" className="text-danger ms-5">
                            {" "}
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
