import React, { useState} from "react";
import Background from "../../../assets/images/auth-bg/bg-1.jpg";
import AioLogo from "../../../assets/images/aio-img.png";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ResetPassword() {
  const { id, token } = useParams();
  // console.log(id, token);
  const [inputValues, setInputValues] = useState({
    password: "",
    repeat_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = () => {
    if (inputValues.password === inputValues.repeat_password) {
      setIsLoading(true);
      const data = {
        password: inputValues.password,
        token: token,
        uidb64: id,
      };
      return makeAPICall({
        path: "/auth/password-reset-complete",
        method: "PATCH",
        payload: data,
      })
        .then((res) => {
          setIsLoading(false);
          toast("Password reset successful, go to login page", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast("Password don't match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div
      className="hold-transition theme-primary bg-img"
      style={{
        backgroundImage: `url(${Background})`,
        height: "100vh",
      }}
    >
      <div className="container h-p100">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <a className="navbar-brand" href="/">
                      <img src={AioLogo} className=" nav-logo" alt="" />
                    </a>
                    {/* <h2 className="text-primary">Let's Get Started</h2> */}
                    <p className="mb-0">Enter New Password</p>
                  </div>
                  <div className="p-40">
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text  bg-transparent">
                          <i className="ti-lock"></i>
                        </span>
                        <input
                          type="password"
                          className="form-control ps-15 bg-transparent"
                          placeholder="Password"
                          value={inputValues.password}
                          onChange={handleChangeInput}
                          name="password"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent">
                          <i className="ti-lock"></i>
                        </span>
                        <input
                          type="password"
                          className="form-control ps-15 bg-transparent"
                          placeholder="Repeat Password"
                          value={inputValues.repeat_password}
                          onChange={handleChangeInput}
                          name="repeat_password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          disabled={
                            !(
                              inputValues.password &&
                              inputValues.repeat_password
                            )
                          }
                          type="submit"
                          className="btn btn-danger mt-10"
                          onClick={handleSubmit}
                        >
                          {isLoading ? "Loading..." : "Change Password"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="mt-15 mb-0">
                        Back to Login
                        <Link to="/login" className="text-danger ms-5">
                          {" "}
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
