import { NavLink } from "react-router-dom";
import "./header.css";

import AioLogo from "../../assets/images/aioWhiteImg.png";

const Header = () => {
  return (
    <header className="top-bar">
      <nav className="navbar navbar-expand-lg navbar-light fixed-bg">
        <div className="container">
          <a className="navbar-brand" href="/">
          <img src={AioLogo} className=" nav-logo" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown">
                <NavLink className="nav-link mx-2 active" data-toggle="dropdown" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link mx-2" aria-current="page" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link mx-2" data-toggle="dropdown"  to="/services">
                  Services
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link mx-2" data-toggle="dropdown"  to="/track">
                  Track
                </NavLink>
              </li>
              {/* <li className="nav-item ">
                <NavLink className="nav-link mx-2" aria-current="page" to="/pricing">
                  Pricing
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link mx-2" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            <br />
            <ul className="navbar-nav ms-auto" >
            <li className="nav-item dropdown">
                <NavLink className="nav-btn-1 mx-2"  to="/login">
                  Login
                </NavLink>
              </li>
              <br />
              <li className="nav-item">
                <NavLink className="nav-btn mx-2" to="/signup">
                  Sign up
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
