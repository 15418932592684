import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import makeAPICall from "../../utils/apiUtils";
import logo from "../../assets/images/aio-img.png";
import Background from "../../assets/images/auth-bg/bg-2.jpg";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  let { token } = useParams();
  const isMounted = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isMounted.current) return;
    const confirmEmail = (token) => {
      setLoading(true);
      return makeAPICall({
        path: `auth/email-verify/?token=${token}`,
        method: "GET",
      })
        .then((res) => {
          setLoading(false);
          navigate("/confirm");
        })
        .catch((err) => {
          setLoading(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    };
    confirmEmail(token);
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const gotoLogin = () => {
    navigate("/login");
    window.localStorage.removeItem("persist:counter");
  };

  return (
    <section
      className="hold-transition theme-primary bg-img"
      style={{
        backgroundImage: `url(${Background})`,
        height: "100vh",
      }}
    >
      <div className="container h-p100">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="p-40 text-center">
                    {loading ? (
                      "...Verifying"
                    ) : (
                      <>
                        <img
                          src={logo}
                          alt="AIO Logo"
                          className="logo mb-3"
                          style={{ width: "200px" }}
                        />
                        <br />
                        <h1 className="congrats mb-3">
                          Congratulations, Your email has been verified.{" "}
                        </h1>
                        <p className="congrats-details mb-5">
                          Welcome to AIO; a platform that gives you amazing
                          logistic experience.
                        </p>
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={gotoLogin}
                        >
                          Proceed to Login
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
