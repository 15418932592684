import React, { useEffect } from "react";
import RiderBackBone from "../../../components/RiderBackBone";
import { useSelector, useDispatch } from "react-redux";
import { getAllSchedulesSelector, getAllSchedules } from "../../../services/slices/rider/allSchedules";
import { getActiveSchedulesSelector, getActiveSchedules } from "../../../services/slices/rider/activeSchedules";

export default function RiderDash() {
  const dispatch = useDispatch();
  const { loading, allSchedules } = useSelector(getAllSchedulesSelector);
  const { loading: loadActiveSchedules, activeSchedules } = useSelector(getActiveSchedulesSelector);

  useEffect(() => {
    dispatch(getAllSchedules({}))
    dispatch(getActiveSchedules({}))
  }, [dispatch]);

  // console.log(schedules, 'test')

  return (
    <RiderBackBone title="Overview">
      <div>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loading ? "Loading..." : allSchedules?.count}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-calendar"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">All Schedules</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadActiveSchedules ? "Loading..." : activeSchedules?.count}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-calendar"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Active Schedules</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RiderBackBone>
  );
}

