import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserSelector,
  getUsers,
} from "../../../services/slices/admin/users";

export default function UserAdmin() {
  const dispatch = useDispatch();
  const { users, loading } = useSelector(getUserSelector);
  const [inputSearchValues, setInputSearchValues] = useState({
    firstName: "",
    email: "",
    phone: "",
  });
  const [userType, setUserType] = useState("");

  useEffect(() => {
    dispatch(getUsers({}))
   }, [dispatch]);

  function handleChangeSearchInput(event) {
    setInputSearchValues({
      ...inputSearchValues,
      [event.target.name]: event.target.value,
    });
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row.firstname}&nbsp;{row.lastname}
        </p>
      ),
    },
    {
      name: "Account Type",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => {
        if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === false &&
          row.is_rider === false &&
          row.is_verified === true
        ) {
          return <p>Vendor</p>;
        } else if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === false &&
          row.is_rider === true &&
          row.is_verified === true
        ) {
          return <p>Rider</p>;
        } else if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === true &&
          row.is_rider === false &&
          row.is_verified === true
        ) {
          return <p>Admin</p>;
        }
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: false,
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getUsers({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getUsers({
        params: { page: page },
      })
    );
  };

  const handleSubmitSearch = () => {
    dispatch(
      getUsers({
        params: {
          search: inputSearchValues.firstName,
          type: userType,
          email: inputSearchValues.email,
          phone: inputSearchValues.phone,
        },
      })
    );
    setInputSearchValues({
      firstName: "",
      email: "",
      phone: "",
    });
    setUserType("")
  };

  return (
    <AdminBackBone title="All Users">
      <div>
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by User Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.firstName}
                name="firstName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">User Type</label>
              <select
                onChange={(e) => setUserType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={userType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="rider">Rider</option>
                <option value="vendor">Vendor</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by Email"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.email}
                name="email"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Phone"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.phone}
                name="phone"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    inputSearchValues.firstName ||
                    userType ||
                    inputSearchValues.email ||
                    inputSearchValues.phone
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={users?.results}
              progressPending={loading}
              pagination
              title="Users"
              paginationServer
              paginationTotalRows={users?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
