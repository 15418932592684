import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  adminRegister: {},
};

export const adminRegisterSlice = createSlice({
  name: "registerAdmin",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.adminRegister = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = adminRegisterSlice.actions;

// A selector
export const getAdminRegisterSelector = (state) => state.registerAdmin;

// The reducer
export default adminRegisterSlice.reducer;

// api call action
export const registerAdminUser = (data) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/auth/admin/signup/",
    payload: data,
    method: "POST",
  })
    .then((res) => {
    //   console.log(res, 'register successful');
      dispatch(getAppSuccess(res.data));
      toast("Admin created successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    .catch((err) => {
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAppFailure(err));
    });
};
