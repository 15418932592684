import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdminRegisterSelector,
  registerAdminUser,
} from "../../../services/slices/auth/adminRegister";
import {
  getUserSelector,
  getUsers,
} from "../../../services/slices/admin/users";
import VendorModal from "../../../components/Modal/vendorModal";
import ReactPhoneInput from "react-phone-input-2";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import EditModal from "../../../components/Modal/editPrice";

export default function UserAdminAdmin() {
  const dispatch = useDispatch();
  const { loading: adminLoading, adminRegister } = useSelector(
    getAdminRegisterSelector
  );
  //   console.log(adminRegister, 'hshsh')
  const { users, loading: loadingUser } = useSelector(getUserSelector);
  const [inputSearchValues, setInputSearchValues] = useState({
    firstName: "",
    email: "",
    phone: "",
  });
  const [userType, setUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
  });
  const [phoneState, setPhoneState] = useState("");
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [editInputValues, setEditInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    id: "",
    is_verified: "",
    is_business: "",
    is_rider: "",
    is_staff: "",
  });

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleOnChangePhoneInputEdit(value) {
    setInputValues({
      ...inputValues,
      phone: value,
    });
  }

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleChangeInputEdit(event) {
    setEditInputValues({
      ...editInputValues,
      [event.target.name]: event.target.value,
    });
  }

  //function to close edit pricing modal on success or using the cancel button on edit pricing modal
  function handleCloseModalOne() {
    document.getElementById("closeButtonOne").click();
  }

  useEffect(() => {
    dispatch(
      getUsers({
        params: { is_staff: "True" },
      })
    );
  }, [dispatch]);

  function handleCloseModalNow() {
    document.getElementById("close-vendor").click();
  }

  function handleChangeSearchInput(event) {
    setInputSearchValues({
      ...inputSearchValues,
      [event.target.name]: event.target.value,
    });
  }

  const showModal = (row) => {
    setEditInputValues({
      first_name: row?.firstname,
      last_name: row?.lastname,
      email: row?.email,
      address: row?.address,
      phone: row?.phone,
      id: row?.id,
      is_verified: row?.is_verified,
      is_business: row?.is_business,
      is_rider: row?.is_rider,
      is_staff: row?.is_staff,
    });
    document.getElementById("openModalBtn").click();
  };

  const handleSubmitEdit = () => {
    setLoading(true);
    const data = {
      email: editInputValues.email,
      firstname: editInputValues.first_name,
      lastname: editInputValues.last_name,
      address: editInputValues.address,
      phone: editInputValues.phone,
      is_business: "False",
      is_verified: "True",
      is_staff: "True",
      is_rider: "False",
    };
    return makeAPICall({
      path: `/auth/user/${editInputValues.id}`,
      payload: data,
      method: "PATCH",
    })
      .then((res) => {
        setLoading(false);
        handleCloseModalOne();
        dispatch(
          getUsers({
            params: { is_staff: "True" },
          })
        );
        setEditInputValues({
          first_name: "",
          last_name: "",
          email: "",
          address: "",
          phone: "",
          id: "",
        });
        toast("Admin has been updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const deletePrice = (id) => {
    setIsLoading({ loading: true, key: id });
    return makeAPICall({
      path: `/auth/user/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        dispatch(
          getUsers({
            params: { is_staff: "True" },
          })
        );
        toast("Admin deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row.firstname}&nbsp;{row.lastname}
        </p>
      ),
    },
    {
      name: "Account Type",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => {
        if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === false &&
          row.is_rider === false &&
          row.is_verified === true
        ) {
          return <p>Vendor</p>;
        } else if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === false &&
          row.is_rider === true &&
          row.is_verified === true
        ) {
          return <p>Rider</p>;
        } else if (
          (row.is_business === true || row.is_business === false) &&
          row.is_staff === true &&
          row.is_rider === false &&
          row.is_verified === true
        ) {
          return <p>Admin</p>;
        }
      },
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "300px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginRight: "10px" }}
              onClick={() => {
                showModal(row);
              }}
            >
              Edit Admin
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => {
                deletePrice(row.id);
              }}
            >
              {isLoading.loading && isLoading.key === row.id
                ? "Loading..."
                : "Delete"}
            </button>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getUsers({
        params: { page: page, is_staff: "True" },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getUsers({
        params: { page: page, is_staff: "True" },
      })
    );
  };

  const handleSubmitSearch = () => {
    dispatch(
      getUsers({
        params: {
          is_staff: "True",
          search: inputSearchValues.firstName,
          type: userType,
          email: inputSearchValues.email,
          phone: inputSearchValues.phone,
        },
      })
    );
    setInputSearchValues({
      firstName: "",
      email: "",
      phone: "",
    });
    setUserType("");
  };

  function signUp() {
    const plus = "+";
    const phoneNumber = plus.concat(phoneState);
    const data = {
      email: inputValues.email,
      firstname: inputValues.first_name,
      lastname: inputValues.last_name,
      address: inputValues.address,
      phone: phoneNumber,
      is_verified: "True",
    };
    dispatch(registerAdminUser(data));
  }

  useEffect(() => {
    if (
      adminRegister &&
      Object.keys(adminRegister).length === 0 &&
      Object.getPrototypeOf(adminRegister) === Object.prototype
    ) {
      console.log("false");
    } else {
      handleCloseModalNow();
      dispatch(
        getUsers({
          params: { is_staff: "True" },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminRegister]);

  return (
    <AdminBackBone title="All Users">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal-vendor"
            >
              Add New Admin
            </button>
            {/* hidden button to open edit price modal */}
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModalOne"
              id="openModalBtn"
              style={{ display: "none" }}
            >
              Edit Admin
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by User Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.firstName}
                name="firstName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">User Type</label>
              <select
                onChange={(e) => setUserType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={userType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="rider">Rider</option>
                <option value="vendor">Vendor</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by Email"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.email}
                name="email"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Phone"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.phone}
                name="phone"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    inputSearchValues.firstName ||
                    userType ||
                    inputSearchValues.email ||
                    inputSearchValues.phone
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={users?.results}
              progressPending={loadingUser}
              pagination
              title="Users"
              paginationServer
              paginationTotalRows={users?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <VendorModal title="Create New Vendor">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="First Name"
                      value={inputValues.first_name}
                      onChange={handleChangeInput}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Last Name"
                      value={inputValues.last_name}
                      onChange={handleChangeInput}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Email"
                      value={inputValues.email}
                      onChange={handleChangeInput}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Address"
                      value={inputValues.address}
                      onChange={handleChangeInput}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={phoneState}
                      onChange={handleOnChangePhoneInput}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalNow}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                disabled={
                  !(
                    inputValues.email &&
                    inputValues.first_name &&
                    inputValues.last_name &&
                    phoneState &&
                    inputValues.address
                  )
                }
                className="btn btn-primary"
                onClick={signUp}
              >
                {adminLoading ? (
                  "Loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Create Admin
                  </span>
                )}
              </button>
            </div>
          </div>
        </VendorModal>
        {/* Modal to edit pricing */}
        <EditModal title="Edit Pricing">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="First Name"
                      value={editInputValues.first_name}
                      onChange={handleChangeInputEdit}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Last Name"
                      value={editInputValues.last_name}
                      onChange={handleChangeInputEdit}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Email"
                      value={editInputValues.email}
                      onChange={handleChangeInputEdit}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Address"
                      value={editInputValues.address}
                      onChange={handleChangeInputEdit}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={editInputValues.phone}
                      onChange={handleOnChangePhoneInputEdit}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalOne}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !(
                    editInputValues.first_name &&
                    editInputValues.last_name &&
                    editInputValues.email &&
                    editInputValues.phone
                  )
                }
                onClick={handleSubmitEdit}
              >
                {loading ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Edit Admin
                  </span>
                )}
              </button>
            </div>
          </div>
        </EditModal>
        {/* Modal to edit pricing end */}
      </div>
    </AdminBackBone>
  );
}
