import React, { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import makeAPICall from "../../utils/apiUtils";
import { toast } from "react-toastify";

import "./Track.css";

const Track = () => {
  const [view, setView] = useState(false);
  const [trackPin, setTrackPin] = useState("");
  const [loader, setLoader] = useState(false);
  const [result, setResult] = useState();

  function handleTrackPin() {
    setLoader(true);
    return makeAPICall({
      path: `/shipping/track/${trackPin}`,
      method: "GET",
    })
      .then((res) => {
        if (res.error) {
          setLoader(false);
          // setView(true);
          setTrackPin("");
          // setResult(res);
          toast(res.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setLoader(false);
          setView(true);
          setTrackPin("");
          setResult(res);
          toast("Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
       
      })
      .catch((err) => {
        setLoader(false);
        setTrackPin("");
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <>
      <section className="track-one">
        <Header />
        <div className="trackHero-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Track</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="trackRoundedBorder">
          <div className="container">
            <div className="row">
              <div className="track-box">
                <p>Track</p>
                <small>Home / Track</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="track-two">
        <div className="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <div class="card card-sm">
                <div class="card-body row no-gutters align-items-center">
                  {/* <!--end of col--> */}
                  <div class="col">
                    <input
                      class="form-control form-control-lg form-control-borderless"
                      type="search"
                      placeholder="Track your item/product using track id"
                      onChange={(e) => setTrackPin(e.target.value)}
                      value={trackPin}
                    />
                  </div>
                  {/* <!--end of col--> */}
                  <div class="col-auto">
                    <button
                      class="search-btn"
                      type="submit"
                      onClick={handleTrackPin}
                      disabled={!trackPin}
                    >
                      {loader ? "Loading..." : <span>Submit</span>}
                    </button>
                  </div>
                  {/* <!--end of col--> */}
                </div>
              </div>
            </div>
            {/* <!--end of col--> */}
          </div>
          {view ? (
            <div className="row">
              <div className="col-md-3 offset-md-4">
                <div className="d-flex justify-content-between">
                  <p style={{ fontWeight: "bold" }}>Status: </p>
                  <p style={{ color: "#FD8B00" }}>
                    Your item is {result?.status}...
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p>Track your item/product</p>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Track;
