import React, { useState } from "react";
import "./accordion.css";

const Accordion = ({ heading, content, number }) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <li className="accordion-item">
            <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
                <p>{number}</p>
                <h6>{heading}</h6><div className="span">{isActive ? "x" : "+"}</div>
            </div>
            {isActive && <div className="accordion-content">{content}</div>}
        </li>
    );
};

export default Accordion;