import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { useSelector, useDispatch } from "react-redux";
import {
  getScheduleSelector,
  getSchedules,
} from "../../../services/slices/admin/schedules";
import DataTable from "react-data-table-component";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactPhoneInput from "react-phone-input-2";

export default function ScheduleAdmin() {
  const dispatch = useDispatch();
  const { loading, schedules } = useSelector(getScheduleSelector);
  const [status, setStatus] = useState("");
  const [inputSearchValues, setInputSearchValues] = useState({
    name: "",
    track_id: "",
    phone: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dispatch(getSchedules({}));
  }, [dispatch]);

  function handleChangeSearchInput(event) {
    setInputSearchValues({
      ...inputSearchValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleOnChangePhoneInput(value) {
    setInputSearchValues({
      ...inputSearchValues,
      phone: value,
    });
  }

  const columns = [
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      cell: (row) => <p>{moment(row.created_at).format("L")}</p>,
    },
    {
      name: "Rider Name",
      selector: (row) => row.rider.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row.rider.firstname}&nbsp;{row.rider.lastname}
        </p>
      ),
    },
    {
      name: "Rider Email",
      selector: (row) => row.rider.email,
      sortable: false,
    },
    {
      name: "Vendor Name",
      selector: (row) => row.shipment.user.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row.shipment.user.firstname}&nbsp;{row.shipment.user.lastname}
        </p>
      ),
    },
    {
      name: "Vendor Email",
      selector: (row) => row.shipment.user.email,
      sortable: false,
    },
    {
      name: "Receiver Name",
      selector: (row) => row.shipment.username,
      sortable: false,
    },
    {
      name: "Receiver Email",
      selector: (row) => row.shipment.email,
      sortable: false,
    },
    {
      name: "Receiver Phone Number",
      selector: (row) => row.shipment.phone,
      sortable: false,
    },
    {
      name: "Receiver Tracking Code",
      selector: (row) => row.shipment.track,
      sortable: false,
    },
    {
      name: "Delivery PIN",
      selector: (row) => row?.shipment?.access_code,
      sortable: false,
    },
    {
      name: "Receiver Shipment Type",
      selector: (row) => row.shipment.ship_type,
      sortable: false,
    },
    {
      name: "Receiver Shipment Weight",
      selector: (row) => row.shipment.weight,
      sortable: false,
    },
    {
      name: "Receiver Shipment Description",
      selector: (row) => row.shipment.description,
      sortable: false,
    },
    {
      name: "Receiver Nearest Bus stop",
      selector: (row) => row.shipment.landmark,
      sortable: false,
    },
    {
      name: "Receiver Address",
      selector: (row) => row.shipment.address,
      sortable: false,
    },
    {
      name: "Receiver City",
      selector: (row) => row.shipment.city,
      sortable: false,
    },
    {
      name: "Receiver State",
      selector: (row) => row.shipment.state,
      sortable: false,
    },
    {
      name: "Receiver Country",
      selector: (row) => row.shipment.country,
      sortable: false,
    },
    {
      name: "Delivered",
      selector: (row) => row.shipment.is_delivered,
      sortable: false,
      cell: (row) => {
        if (row.shipment.is_delivered) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Within Lagos",
      selector: (row) => row.shipment.is_managed,
      sortable: false,
      cell: (row) => {
        if (row.shipment.is_managed) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getSchedules({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getSchedules({
        params: { page: page },
      })
    );
  };

  const handleSubmitSearch = () => {
    dispatch(
      getSchedules({
        params: {
          status: status,
          shipment__track: inputSearchValues.track_id,
          shipment__user__phone: inputSearchValues.phone,
          search: inputSearchValues.name,
          start: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          end: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        },
      })
    );
    setInputSearchValues({
      name: "",
      track_id: "",
      phone: "",
    });
    setStatus("");
    setStartDate(null);
    setEndDate(null)
  };

  return (
    <AdminBackBone title="All Schedules">
      <div>
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Tracking Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Tracking code"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.track_id}
                name="track_id"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone Number</label>
              <ReactPhoneInput
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                  className: "formInput",
                }}
                value={inputSearchValues.phone}
                onChange={handleOnChangePhoneInput}
                country="ng"
                placeholder="23480212345678"
                autoFormat={false}
                enableClickOutside={true}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Rider Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.name}
                name="name"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Status</label>
              <select
                onChange={(e) => setStatus(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={status}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pending">Pending</option>
                <option value="shipping">Shipping</option>
                <option value="delivered">Delivered</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    inputSearchValues.name ||
                    inputSearchValues.track_id ||
                    status ||
                    startDate ||
                    endDate ||
                    inputSearchValues.phone
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={schedules?.results}
              progressPending={loading}
              pagination
              title="Schedules"
              paginationServer
              paginationTotalRows={schedules?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
