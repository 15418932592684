import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactionSelector,
  getTransactions,
} from "../../../services/slices/admin/transactions";
import DataTable from "react-data-table-component";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import DatePicker from "react-datepicker";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";

export default function TransactionAdmin() {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector(getTransactionSelector);
  const [transactionType, setTransactionType] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [walletTable, setWalletTable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  useEffect(() => {
    if (transactions) setWalletTable(transactions);
  }, [transactions]);


  useEffect(() => {
    dispatch(getTransactions({}))
   }, [dispatch]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row?.created_at,
      sortable: false,
      cell: (row) => <p>{moment(row?.created_at).format("L")}</p>,
    },
    {
      name: "Vendor Name",
      selector: (row) => row?.owner?.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row?.owner?.firstname}&nbsp;{row?.owner?.lastname}
        </p>
      ),
    },
    {
      name: "Vendor Email",
      selector: (row) => row?.owner?.email,
      sortable: false,
    },
    {
      name: "Transaction ID",
      selector: (row) => row?.transaction,
      sortable: false,
    },
    {
      name: "Transaction Type",
      selector: (row) => row?.type,
      sortable: false,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: false,
      cell: (row) => <p>₦{formatMoney(row?.amount)}</p>,
    },
    {
      name: "Creditor Name",
      selector: (row) => row?.credited_by?.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row?.credited_by?.firstname}&nbsp;{row?.credited_by?.lastname}
        </p>
      ),
    },
    {
      name: "Creditor Email",
      selector: (row) => row?.credited_by?.email,
      sortable: false,
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getTransactions({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getTransactions({
        params: { page: page },
      })
    );
  };

  const handleSubmit = () => {
    dispatch(
      getTransactions({
        params: {
          transaction: name,
          amount: amount,
          type: transactionType.toUpperCase(),
        },
      })
    );
    setName("");
    setAmount("");
    setTransactionType("");
  };

  const handleSubmitRange = () => {
    setIsLoading(true)
    return makeAPICall({
      path: `/wallet/admin/transaction/history/?start=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&end=${moment(endDate).format("YYYY-MM-DD")}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status === "error") {
          setIsLoading(false);
          setStartDate(new Date());
          setEndDate(null);
          toast(res.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setIsLoading(false);
          setStartDate(new Date());
          setWalletTable(res);
          setEndDate(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSubmitDownload = () => {
    
    if (startDate && endDate) {
      setIsLoadingDownload(true)
      return makeAPICall({
        path: `/wallet/admin/export/pdf/?start=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&end=${moment(endDate).format("YYYY-MM-DD")}`,
        method: "GET",
      })
        .then((res) => {
          console.log(res)
          setIsLoadingDownload(false);
            toast(res.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
        })
        .catch((err) => {
          setIsLoading(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      return makeAPICall({
        path: `/wallet/admin/export/pdf`,
        method: "GET",
      })
        .then((res) => {
        console.log(res)
        window.open(res, '_blank');
        })
        .catch((err) => {
          setIsLoadingDownload(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
   
  };


  return (
    <AdminBackBone title="All Transactions">
      <div>
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Transaction ID</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Transaction ID"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Transaction Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Search by amount"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Transaction Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="credit">Credit</option>
                <option value="debit">Debit</option>
              </select>
            </div>

            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={!(name || transactionType || amount)}
                onClick={handleSubmit}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className="col-md-2">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={!(startDate && endDate)}
                onClick={handleSubmitRange}
              >
                <span>Search By Date
                </span>
              </button>
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                onClick={handleSubmitDownload}
              >
               {isLoadingDownload ? 'Exporting...' : "Export To PDF"}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={walletTable?.results}
              progressPending={loading || isLoading}
              pagination
              title="Payment History"
              paginationServer
              paginationTotalRows={walletTable?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
