import React, { useState, useEffect, useRef } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderSelector,
  getOrders,
} from "../../../services/slices/admin/orders";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import RightModal from "../../../components/Modal/rightModal";
import PriceModal from "../../../components/Modal/shippingPrice";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import {
  getVendorUserSelector,
  getVendorUsers,
} from "../../../services/slices/admin/vendor";
import debounce from "lodash.debounce";
import {
  pdf,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "20px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "15%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 20,
    marginBottom: 10,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 5,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 10,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    marginRight: "10px",
    marginBottom: "5px",
    width: "25%",
  },
  rightText: {
    color: "#040052",
    fontSize: 13,
    fontWeight: 700,
    marginBottom: "5px",
  },
});

export default function OrderAdmin() {
  const dispatch = useDispatch();
  const { orders, loading: loadingOrder } = useSelector(getOrderSelector);
  const { vendorUsers, loading: isLoadingVendor } = useSelector(
    getVendorUserSelector
  );
  const [orderDetails, setOrderDetails] = useState();
  const [weight, setWeight] = useState("");
  const [vendorName, setVendorName] = useState({ value: "", id: "" });
  const [logisticsType, setLogisticsType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [loadingNowNow, setLoadingNowNow] = useState(false);
  const [shipmentType, setShipmentType] = useState("");
  const [vendorOptions, setVendorOptions] = useState();

  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    amount: "",
    description: "",
    landmark: "",
    phoneNumber: "",
  });

  const MyDocument = ({ row }) => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/aio-img.png" />
          <Text style={styles.date}>+2348022717162, +2348168399532</Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Hi {row?.user?.firstname},</Text>
          <Text style={styles.userName}>
            You have created an order on AIO Logistics Network
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Order details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Name</Text>
          <Text style={styles.rightText}>{row?.username}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Phone Number</Text>
          <Text style={styles.rightText}>{row?.phone}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Email</Text>
          <Text style={styles.rightText}>{row?.email}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Address</Text>
          <Text style={styles.rightText}>{row?.address}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Landmark</Text>
          <Text style={styles.rightText}>{row?.landmark}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>City</Text>
          <Text style={styles.rightText}>{row?.city}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>State</Text>
          <Text style={styles.rightText}>{row?.state}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Country</Text>
          <Text style={styles.rightText}>{row?.country}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Item Weight</Text>
          <Text style={styles.rightText}>{row?.weight}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Amount</Text>
          <Text style={styles.rightText}>N{formatMoney(row?.amount)}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Ship Type</Text>
          <Text style={styles.rightText}>{row?.ship_type}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Logistic Type</Text>
          <Text style={styles.rightText}>{row?.logistic_type}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Tracking ID</Text>
          <Text style={styles.rightText}>{row?.track}</Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The AIO team</Text>
        </View>
      </Page>
    </Document>
  );

  const generatePdfDocument = async (row) => {
    const blob = await pdf(<MyDocument row={row} />).toBlob();
    saveAs(blob, `order-${row?.user?.firstname}.pdf`);
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function showModal(row) {
    setOrderDetails(row);
    document.getElementById("showMod").click();
  }

  function handleOnChangePhoneInput(value) {
    setInputValues({
      ...inputValues,
      phoneNumber: value,
    });
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.username,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: false,
      width: "200px",
    },
    {
      name: "Tracking Number",
      selector: (row) => row?.track,
      sortable: false,
      width: "200px",
    },
    {
      name: "Item Delivered",
      selector: (row) => row?.is_delivered,
      sortable: false,
      cell: (row) => {
        if (row.is_delivered) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Rider Assigned",
      selector: (row) => row?.id,
      sortable: false,
      cell: (row) => {
        if (
          (row?.is_delivered || row.is_delivered === false) &&
          row?.riderStatus.length > 0
        ) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: false,
      cell: (row) => <p>₦{formatMoney(row?.amount)}</p>,
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "300px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => showModal(row)}
              style={{ marginRight: "10px" }}
            >
              View More
            </button>
            <button
              className="btn btn-primary"
              onClick={() => generatePdfDocument(row)}
            >
              Download
            </button>
          </div>
        );
      },
    },
  ];

  function handlePageChange(page) {
    dispatch(
      getOrders({
        params: { page: page },
      })
    );
  }

  async function handlePerRowsChange(newPerPage, page) {
    dispatch(
      getOrders({
        params: { page: page },
      })
    );
  }

  function handleCloseModalPrice() {
    document.getElementById("priceView").click();
    getVendorUsers({});
  }

  const handleSubmitSearch = () => {
    dispatch(
      getOrders({
        params: {
          email: email,
          username: name,
          phone: phone,
          ship_type: transactionType,
          country: country,
          state: region,
        },
      })
    );
    setName("");
    setEmail("");
    setTransactionType("");
    setCountry("");
    setRegion("");
    setPhone("");
  };

  useEffect(() => {
    if (vendorUsers) {
      const newArray = vendorUsers?.results?.map((obj) => ({
        label: obj.firstname + " " + obj.lastname,
        value: obj.id,
      }));
      setVendorOptions(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorUsers]);

  useEffect(() => {
    dispatch(getOrders({}));
  }, [dispatch]);

  const handleChangeNow = (label, value) => {
    // console.log(label, value)
    setVendorName({ value: label, id: value });
  };

  const handleSearchDebounced = useRef(
    debounce((searchText) => handleSearch(searchText), 300)
  ).current;

  const handleInputChange = (inputText, meta) => {
    if (meta?.action !== "input-blur" && meta?.action !== "menu-close") {
      // const { value } = meta?.option;
      setVendorName({ value: inputText, id: "" });
      handleSearchDebounced(inputText);
    }
  };

  const handleSearch = async (searchQuery) => {
    if (searchQuery.trim().length === 0) {
      setVendorOptions([]);
      return;
    } else {
      dispatch(
        getVendorUsers({
          params: {
            search: searchQuery,
          },
        })
      );
    }
  };

  const noOptionsMessage = (obj) => {
    if (obj?.inputValue.trim().length === 0) {
      return null;
    }
    return "No matching vendor";
  };

  function handleSubmitNew() {
    // console.log(shipmentId, "test");
    setLoadingNowNow(true);
    const dataNow = {
      username: `${inputValues.first_name} ${inputValues.last_name}`,
      email: inputValues.email,
      phone: inputValues.phoneNumber,
      address: inputValues.address,
      ship_type: shipmentType,
      landmark: inputValues.landmark,
      amount: inputValues.amount,
      city: inputValues.city,
      state: region,
      country: country,
      weight: weight,
      description: inputValues.description,
      user: vendorName.id,
      logistic_type: logisticsType,
    };
    return makeAPICall({
      path: "/shipping/admin/order/",
      method: "POST",
      payload: dataNow,
    })
      .then((res) => {
        setLoadingNowNow(false);
        handleCloseModalPrice();
        dispatch(getOrders({}));
        dispatch(getVendorUsers({}));
        toast(
          "You have created an order, please check the orders page to view order",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch((err) => {
        setLoadingNowNow(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <AdminBackBone title="All Orders">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal-price"
              // id="openModalPriceNow"
              // style={{ display: "none" }}
            >
              Create Order
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Ship Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={country}
                classes="selectDrop"
                onChange={(val) => setCountry(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">State</label>
              <RegionDropdown
                country={country}
                classes="selectDrop"
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    name ||
                    transactionType ||
                    email ||
                    phone ||
                    country ||
                    region
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={orders?.results}
              progressPending={loadingOrder}
              pagination
              title="Orders"
              paginationServer
              paginationTotalRows={orders?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          id="showMod"
          data-bs-toggle="modal"
          data-bs-target="#modal-right"
          style={{ display: "none" }}
        >
          View More
        </button>

        <RightModal title={orderDetails?.username}>
          <div>
            <h1 style={{ fontSize: "18px" }}>Shipment Information</h1>
            <hr />
            <div>
              <h1 style={{ fontSize: "16px" }}>Shipment Number</h1>
              <p style={{ fontSize: "14px" }}>{orderDetails?.id}</p>
              <h1 style={{ fontSize: "16px" }}>Shipment Status</h1>
              <p style={{ fontSize: "14px" }}>
                {orderDetails?.riderStatus[0]?.status}
              </p>
              <h1 style={{ fontSize: "16px" }}>Date</h1>
              <p style={{ fontSize: "14px" }}>
                {moment(orderDetails?.riderStatus[0]?.updated_at).format("L")}
              </p>
            </div>
            <hr />
            <h1 style={{ fontSize: "18px" }}>Order Information</h1>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Address</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.address}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Shipment Type</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.ship_type}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Landmark</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.landmark}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>City</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.city}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>State</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.state}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Country</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.country}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Description</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.description}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Weight</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.weight}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Delivered</h1>
                <p style={{ fontSize: "14px" }}>
                  {orderDetails?.is_delivered ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Within Lagos?</h1>
                <p style={{ fontSize: "14px" }}>
                  {orderDetails?.is_managed ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </RightModal>

        {/* modal to create order */}
        <PriceModal title="Create An Order">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Okon"
                      onChange={handleChangeInput}
                      value={inputValues.first_name}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Hamza"
                      onChange={handleChangeInput}
                      value={inputValues.last_name}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="e.g. example@mail.com"
                      onChange={handleChangeInput}
                      value={inputValues.email}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. No 1. Ijebu Ode Street"
                      onChange={handleChangeInput}
                      value={inputValues.address}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Shipment Type</label>
                    <select
                      onChange={(e) => setShipmentType(e.target.value)}
                      className="selectDrop"
                      // name="riderSelect"
                      value={shipmentType}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="delivery">Delivery</option>
                      <option value="pickup">Pick Up</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={inputValues.phone}
                      onChange={handleOnChangePhoneInput}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <CountryDropdown
                      value={country}
                      classes="selectDrop"
                      onChange={(val) => setCountry(val)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <RegionDropdown
                      country={country}
                      classes="selectDrop"
                      value={region}
                      onChange={(val) => setRegion(val)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Akoka"
                      onChange={handleChangeInput}
                      value={inputValues.city}
                      name="city"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Pako bus Stop"
                      onChange={handleChangeInput}
                      value={inputValues.landmark}
                      name="landmark"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Item Weight</label>
                    <select
                      onChange={(e) => setWeight(e.target.value)}
                      className="selectDrop"
                      // name="riderSelect"
                      value={weight}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="0.5kg">0.5kg</option>
                      <option value="1kg">1kg</option>
                      <option value="2kg">2kg</option>
                      <option value="3kg">3kg</option>
                      <option value="4kg">4kg</option>
                      <option value="5kg">5kg</option>
                      <option value="6kg">6kg</option>
                      <option value="7kg">7kg</option>
                      <option value="8kg">8kg</option>
                      <option value="9kg">9kg</option>
                      <option value="10kg">10kg</option>
                      <option value="above10kg">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Item Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. an iphone 13 pro max"
                      onChange={handleChangeInput}
                      value={inputValues.description}
                      name="description"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Logistics Type</label>
                    <select
                      onChange={(e) => setLogisticsType(e.target.value)}
                      className="selectDrop"
                      // name="riderSelect"
                      value={logisticsType}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="bike">Bike</option>
                      <option value="van">Van</option>
                      <option value="warehouse">Drop-off</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Select Vendor</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isLoading={isLoadingVendor}
                      isClearable={true}
                      value={vendorName.value}
                      onChange={(opt) => handleChangeNow(opt.label, opt.value)}
                      onInputChange={handleInputChange}
                      inputValue={vendorName.value}
                      options={vendorOptions}
                      filterOption={null}
                      noOptionsMessage={noOptionsMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Order Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g. 2000"
                      onChange={handleChangeInput}
                      value={inputValues.amount}
                      name="amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalPrice}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmitNew}
                disabled={
                  !(
                    weight &&
                    shipmentType &&
                    logisticsType &&
                    country &&
                    region &&
                    inputValues.city &&
                    inputValues.landmark &&
                    inputValues.address &&
                    inputValues.description &&
                    inputValues.email &&
                    inputValues.last_name &&
                    inputValues.first_name &&
                    vendorName.value &&
                    inputValues.phoneNumber
                  )
                }
              >
                {loadingNowNow ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Create Order
                  </span>
                )}
              </button>
            </div>
          </div>
        </PriceModal>
      </div>
    </AdminBackBone>
  );
}
