import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { formatMoney } from "../../../utils/helperFunctions";

export default function PayOrderAdmin() {
  const [transactionType, setTransactionType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });

  function unPaidOrders(params = null) {
    // console.log(shipmentId, "test");
    setLoading(true);
    return makeAPICall({
      path: `/shipping/admin/unpaid/orders/`,
      method: "GET",
      params,
    })
      .then((res) => {
        setLoading(false);
        // console.log(res, 'orders');
        setOrders(res);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    unPaidOrders();
  }, []);

  const columns = [
    {
      name: "Receiver Name",
      selector: (row) => row?.username,
      sortable: false,
      width: "200px",
    },
    {
      name: "Receiver Email",
      selector: (row) => row?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Vendor Name",
      selector: (row) => row?.user?.firstname,
      sortable: false,
      width: "200px",
      cell: (row) => {
        return <p>{row?.user?.firstname} {row?.user?.lastname}</p>;
      },
    },
    {
      name: "Vendor Email",
      selector: (row) => row?.user?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Order Amount",
      selector: (row) => row?.amount,
      sortable: false,
      width: "120px",
      cell: (row) => {
        return <p>₦{formatMoney(row?.amount)}</p>;
      },
    },
    {
      name: "Wallet Balance",
      selector: (row) => row?.walletBalance,
      sortable: false,
      width: "120px",
      cell: (row) => {
        return <p>₦{formatMoney(row?.walletBalance)}</p>;
      },
    },
    {
      name: "Tracking Number",
      selector: (row) => row?.track,
      sortable: false,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "200px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => payNow(row.id)}
              style={{ marginRight: "10px" }}
            >
              {isLoading.loading && isLoading.key === row.id
                ? "Loading..."
                : "Pay For Vendor"}
            </button>
          </div>
        );
      },
    },
  ];

  function handlePageChange(page) {
    unPaidOrders({
      params: { page: page },
    });
  }

  async function handlePerRowsChange(newPerPage, page) {
    unPaidOrders({
      params: { page: page },
    });
  }

  function payNow(id) {
    setIsLoading({ loading: true, key: id });
    return makeAPICall({
      path: `/shipping/admin/pay/${id}`,
      method: "PATCH",
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        unPaidOrders({});
        toast("Order has been paid", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handleSubmitSearch = () => {
    unPaidOrders({
      params: {
        email: email,
        username: name,
        phone: phone,
        ship_type: transactionType,
        country: country,
        state: region,
      },
    });
    setName("");
    setEmail("");
    setTransactionType("");
    setCountry("");
    setRegion("");
    setPhone("");
  };

  return (
    <AdminBackBone title="Pay Vendor">
      <div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Ship Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={country}
                classes="selectDrop"
                onChange={(val) => setCountry(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">State</label>
              <RegionDropdown
                country={country}
                classes="selectDrop"
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    name ||
                    transactionType ||
                    email ||
                    phone ||
                    country ||
                    region
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={orders?.results}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={orders?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
