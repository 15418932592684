import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../pages/landing";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/password";
import ResetPassword from "../pages/auth/password/passwordReset";
import Register from "../pages/auth/signup";
import SuperAdmin from "../pages/admin/admin/index";
import UserAdmin from "../pages/admin/admin/users";
import UserAdminAdmin from "../pages/admin/admin/admin";
import VendorUserAdmin from "../pages/admin/admin/vendors";
import AssignRiderAdmin from "../pages/admin/admin/assignRider";
import ShippingAdmin from "../pages/admin/admin/setShipping";
import PayOrderAdmin from "../pages/admin/admin/payOrder";
import TransactionAdmin from "../pages/admin/admin/transactions";
import OrderAdmin from "../pages/admin/admin/orders";
import PricingAdmin from "../pages/admin/admin/pricing";
import ScheduleAdmin from "../pages/admin/admin/schedules";
import RiderAdmin from "../pages/admin/admin/rider";
import VendorDash from "../pages/admin/vendor";
import TransactionVendorDash from "../pages/admin/vendor/transactions";
import OrdersVendorDash from "../pages/admin/vendor/orders";
import RiderDash from "../pages/admin/dispatcher";
import SchedulesDash from "../pages/admin/dispatcher/schedules";
import PrivateRoute from "./privateRoute";
import Contact from "../pages/contact";
import About from "../pages/about";
import ServicesPage from "../pages/services";
import ConfirmEmail from "../pages/CallBackPage";
import GoToTop from "../components/goToTop/GoToTop";
import Track from "../pages/track";

const AllPages = () => (
  <>
    <GoToTop />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="services" element={<ServicesPage />}></Route>
        <Route path="track" element={<Track />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="forgot-password" element={<ForgotPassword />}></Route>
        <Route path="auth/password-reset" element={<ResetPassword />}>
          <Route path=":id/:token" element={<ResetPassword />} />
        </Route>

        <Route path="signup" element={<Register />}></Route>
        <Route path="confirm" element={<ConfirmEmail />}>
          <Route path=":token" element={<ConfirmEmail />} />
        </Route>
        {/* admin routes begin */}
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <SuperAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/admin"
          element={
            <PrivateRoute>
              <UserAdminAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/users"
          element={
            <PrivateRoute>
              <UserAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/vendors"
          element={
            <PrivateRoute>
              <VendorUserAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/transactions"
          element={
            <PrivateRoute>
              <TransactionAdmin />
            </PrivateRoute>
          }
        ></Route>
        {/* <Route
          path="admin/orders"
          element={
            <PrivateRoute>
              <OrderAdmin />
            </PrivateRoute>
          }
        ></Route> */}
        <Route
          path="admin/orders"
        >
           <Route
            index={true}
            element={
              <PrivateRoute>
                 <OrderAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="assign"
            element={
              <PrivateRoute>
                <AssignRiderAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="set-shipping-fee"
            element={
              <PrivateRoute>
                <ShippingAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="pay"
            element={
              <PrivateRoute>
                <PayOrderAdmin />
              </PrivateRoute>
            }
          />
        </Route>
        {/* <Route
          path="admin/assign"
          element={
            <PrivateRoute>
              <AssignRiderAdmin />
            </PrivateRoute>
          }
        ></Route> */}
        {/* <Route
          path="admin/set-shipping-fee"
          element={
            <PrivateRoute>
              <ShippingAdmin />
            </PrivateRoute>
          }
        ></Route> */}
        {/* <Route
          path="admin/pay"
          element={
            <PrivateRoute>
              <PayOrderAdmin />
            </PrivateRoute>
          }
        ></Route> */}
        <Route
          path="admin/pricing"
          element={
            <PrivateRoute>
              <PricingAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/schedules"
          element={
            <PrivateRoute>
              <ScheduleAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="admin/riders"
          element={
            <PrivateRoute>
              <RiderAdmin />
            </PrivateRoute>
          }
        ></Route>
        {/* admin routes end */}
        {/* vendor routes begin */}
        <Route
          path="/vendor"
          element={
            <PrivateRoute>
              <VendorDash />
            </PrivateRoute>
          }
        >
          <Route path=":trxref" element={<VendorDash />} />
        </Route>
        <Route
          path="vendor/transactions"
          element={
            <PrivateRoute>
              <TransactionVendorDash />
            </PrivateRoute>
          }
        />
        <Route
          path="vendor/orders"
          element={
            <PrivateRoute>
              <OrdersVendorDash />
            </PrivateRoute>
          }
        />
        {/*  */}
        {/* vendor routes end */}
        {/* rider routes end */}
        <Route
          path="/rider/overview"
          element={
            <PrivateRoute>
              <RiderDash />
            </PrivateRoute>
          }
        />
        <Route
          path="/rider"
          element={
            <PrivateRoute>
              <SchedulesDash />
            </PrivateRoute>
          }
        />
        {/* rider routes begin */}
      </Routes>
    </BrowserRouter>
  </>
);

export default AllPages;
