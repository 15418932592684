import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import ProgressBar from "./ProgressBar";

import "./landing.css";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AirIcon from "../../assets/images/airIcon.png";
import ArrowImg from "../../assets/images/arrowImg.png";
import ArrowRightImg from "../../assets/images/arrowRight.png";
// import AwardWinIcon from "../../assets/images/award-win.png";
import AwardImg from "../../assets/images/award.png";
import CargoShippingIcon from "../../assets/images/cargoIcon.png";
import Box1 from "../../assets/images/home1.png";
import Box2 from "../../assets/images/home2.png";
import Box3 from "../../assets/images/home3.png";
// import TeamMembersTwo from "../../assets/images/homeTeam2.png";
import TrapeImg from "../../assets/images/homeTrapeIng.png";
import LinesIconRight from "../../assets/images/linesIconRight.png";
// import OntimeDeliveryIcon from "../../assets/images/online-delivery.png";
// import OrderDeliveryIcon from "../../assets/images/order-delivery.png";
import RoadIcon from "../../assets/images/roadIcon.png";
// import SatisyCustomerIcon from "../../assets/images/satisfy-customers.png";
import TruckIcon from "../../assets/images/truckIcon.png";
import { getDispatcher } from "../../services/slices/dispatcher";

export default function Landing() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDispatcher({}));
  }, [dispatch]);
  return (
    <>
      <section className="home-section-one">
        <Navbar />
        <div className="hero-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3>Welcome to AIO Logistics Network LTD!</h3>
                <span>Your Home of Logistics Solutions</span>
                <p>
                We offer deliveries to most major cities
                  both within Nigeria and international no matter the size as this service allows for
                  items of various sizes, shapes and weight to be delivered
                  across the nation.
                </p>
                <Link to="/signup" className="hero-section-btn">
                  Get Started Now
                </Link>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-two">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row pe-3">
                <div className="col-md-6">
                  <img src={Box1} className="img-fluid img-box" alt="" />
                  <img src={Box2} className="img-fluid img-box" alt="" />
                </div>
                <div className="col-md-6">
                  <img src={Box3} alt="" className="img-fluid img-boxes" />
                  <span className="award-box">
                    <img src={AwardImg} alt="" className="award-img" />
                    <div>
                      <p className="totalCount">100+</p>
                      <p>Award Wining Company</p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="second-column"
                style={{ margin: "0px 0px 0px 50px", position: "relative" }}
              >
                <div>
                  <img src={ArrowImg} alt="" className="arrow-img" />
                </div>
                <span>Who We Are</span>
                {/* <span className="outer-span">Who We Are</span> */}

                <h3>We Provide Courier, Logistics Services Nationwide</h3>
                <p className="bold-text">
                  We will take care of your cargo and deliver
                  them safe and on time
                </p>
                <p>
                  We provide advanced solutions through our value-creating
                  services that will allow companies to make the right moves for
                  their business.
                </p>
                <p>
                  Our experienced and dedicated professionals provide companies
                  with international/domestic shipping and logistics solutions.
                </p>
                <Link to="/about" className="home-section-twoBtn">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-three">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>We are Optimists who Love to Work Together</h3>
              <p>
                We are committed to sustainability and how our industry is
                helping deliver a sustainable future for businesses
              </p>
            </div>
          </div>
          <div className="row row-grid">
            <div className="col-md-3">
              <div className="section-card">
                <img
                  src={CargoShippingIcon}
                  alt="card-img"
                  className="card-img"
                />
                <p className="card-head">Nationwide Delivery</p>
                <span>
                  We offer nationwide domestic deliveries to most major cities
                  within Nigeria no matter the size as this service allows for
                  items of various sizes, shapes and weight to be delivered
                  across the nation.
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={AirIcon} alt="card-img" className="card-img" />
                <p className="card-head">Worldwide Delivery</p>
                <span>
                  We operate express delivery to over 220 countries in the world
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={TruckIcon} alt="card-img" className="card-img" />
                <p className="card-head">Van/Truck Delivery</p>
                <span>
                  Our fleet of vans and trucks in sizes 3 tons, 3.5 tons, 10
                  tons, and 15 tons are available for heavy moving. With this
                  service we make Relocation of home or office simple and hassle
                  free. We can pick up from your location and move all items to
                  your new location with the highest professional standards
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={RoadIcon} alt="card-img" className="card-img" />
                <p className="card-head">Dispatch Bike Delivery</p>
                <span>
                  Fast and efficient pick up and delivery based on your
                  companies need. Whether as a Ecommerce vendor or corporate
                  entity
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center" style={{ marginTop: 30 }}>
              <Link to="/services" className="more-btn">
                See All
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-four">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="second-column"
                style={{ margin: "0px 0px 0px 50px", position: "relative" }}
              >
                <div>
                  <img src={ArrowRightImg} alt="" className="arrowRight-img" />
                </div>
                <span>Top Services</span>
                {/* <span className="outer-span">Who We Are</span> */}

                <h3>Top International Transportational Services</h3>
                <p>
                We offer deliveries to most major cities both within Nigeria and international no matter the size as this service allows for items of various sizes, shapes and weight to be delivered across the nation.
                </p>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="bold-text">Worldwide Delivery</p>
                    <ProgressBar bgcolor="#FE8C00" progress="100" height={6} />
                    <small>90%</small>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="bold-text">Nationwide Delivery</p>
                    <ProgressBar bgcolor="#FE8C00" progress="98" height={6} />
                    <small>100%</small>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="bold-text">Dispatch Bike Delivery</p>
                    <ProgressBar bgcolor="#FE8C00" progress="95" height={6} />
                    <small>105%</small>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="bold-text">Van/Truck Delivery</p>
                    <ProgressBar bgcolor="#FE8C00" progress="99" height={6} />
                    <small>100%</small>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="bold-text">Warehousing</p>
                    <ProgressBar bgcolor="#FE8C00" progress="99" height={6} />
                    <small>100%</small>
                  </div>
                </div>

                <Link to="/signup" className="home-section-fourBtn">
                  Get Started Now
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={LinesIconRight}
                alt="lines-right"
                className="lines-right"
              />
              <img src={TrapeImg} alt="trape-img" className="trape-img" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="home-section-five">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>We Make Your Life More Easy & Comfortable</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2 text-center pt-3">
              <img src={OrderDeliveryIcon} alt="" className="sectionfive-img" />
              <p className="head">3530+</p>
              <p>Order Delivery</p>
            </div>
            <div className="col-md-2 text-center pt-3">
              <img
                src={OntimeDeliveryIcon}
                alt=""
                className="sectionfive-img"
              />
              <p className="head">3240+</p>
              <p>Ontime Delivery</p>
            </div>
            <div className="col-md-2 text-center pt-3">
              <img
                src={SatisyCustomerIcon}
                alt=""
                className="sectionfive-img"
              />
              <p className="head">2532+</p>
              <p>Satisfy Customers</p>
            </div>
            <div className="col-md-2 text-center pt-3">
              <img src={AwardWinIcon} alt="" className="sectionfive-img" />
              <p className="head">1500+</p>
              <p>Awards Winning</p>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>

      <section className="home-section-six">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="second-column"
                style={{ margin: "0px 0px 0px 50px", position: "relative" }}
              >
                <div>
                  <img src={ArrowRightImg} alt="" className="arrowRight-img" />
                </div>
                <span>Team Members</span>

                <h3>Meat Our Pro Skilled Team Members</h3>
                <p>
                  Lorem ipsum dolor sit amet adipisicing eiusmod tempor
                  elementum ut labore et dolore magna aliqua ad minim veniam,
                  quis nostrud exercitationm dolor sit amet adipisicing eiusmod
                  tempor elementum ut labore et dolore. labore et dolore magna
                  aliqua ad minim veniam, quis nostrud exercitationm dolo
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-5" style={{ position: "relative" }}>
              <img
                src={LinesIconRight}
                alt="lines-right"
                className="lines-right"
              />
              <div className="row">
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">James Ferabi</p>
                  <p className="p-bottom">AC Manager</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Zakson Rodri</p>
                  <p className="p-bottom">CTO Officer</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Hames Abedi</p>
                  <p className="p-bottom">Designer</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Moriba Raido</p>
                  <p className="p-bottom">Supporter</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">James Ferabi</p>
                  <p className="p-bottom">AC Manager</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Zakson Rodri</p>
                  <p className="p-bottom">CTO Officer</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Hames Abedi</p>
                  <p className="p-bottom">Designer</p>
                </div>
                <div className="col-md-3 text-center">
                  <img src={TeamMembersTwo} alt="" className="rectangle-img" />
                  <p className="p-head">Moriba Raido</p>
                  <p className="p-bottom">Supporter</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="home-section-seven">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>We Delivered 3530+ Products with Satisfiction</h3>
              <p>
                We will get your items delivered to its last mile destination
                with no hassles
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
