import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditModal from "../../../components/Modal/editPrice";
import RightModal from "../../../components/Modal/rightModal";
import RiderBackBone from "../../../components/RiderBackBone";
import { getLoginSelector } from "../../../services/slices/auth/login";
import {
  getAllSchedules,
  getAllSchedulesSelector,
} from "../../../services/slices/rider/allSchedules";
import makeAPICall from "../../../utils/apiUtils";
import moment from "moment";
import DatePicker from "react-datepicker";

export default function SchedulesDash() {
  const dispatch = useDispatch();
  const { user } = useSelector(getLoginSelector);
  const { loading, allSchedules } = useSelector(getAllSchedulesSelector);
  const [scheduleDetails, setScheduleDetails] = useState();
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [isLoadingAttempt, setIsLoadingAttempt] = useState({
    loading: false,
    key: 0,
  });
  const [loader, setLoader] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [id, setId] = useState();
  const showModal = (row) => {
    setScheduleDetails(row);
    document.getElementById("showMod").click();
  };
  // const [status, setStatus] = useState("");
  // const [inputSearchValues, setInputSearchValues] = useState({
  //   name: "",
  //   track_id: "",
  // });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  // function handleChangeSearchInput(event) {
  //   setInputSearchValues({
  //     ...inputSearchValues,
  //     [event.target.name]: event.target.value,
  //   });
  // }

  useEffect(() => {
    dispatch(getAllSchedules({}));
  }, [dispatch]);

  //function to price details and fire up edit modal
  const showMediumModal = (row) => {
    setId(row);
    document.getElementById("openModalBtn").click();
  };

  const startRideHandle = (id) => {
    setIsLoading({ loading: true, key: id });
    const dataNow = {
      shipment: id,
      rider: user?.id,
    };
    return makeAPICall({
      path: `/rider/start/${id}`,
      method: "POST",
      payload: dataNow,
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        dispatch(getAllSchedules({}));
        toast("Trip has started", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const attemptOrder = (id) => {
    setIsLoadingAttempt({ loading: true, key: id });
    return makeAPICall({
      path: `/rider/attempt/${id}`,
      method: "GET",
    })
      .then((res) => {
        setIsLoadingAttempt({ loading: false, key: 0 });
        dispatch(getAllSchedules({}));
        toast(
          "Thank you, we have received your message about the customer not available",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch((err) => {
        setIsLoadingAttempt({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      name: "Receiver Info",
      selector: (row) => row.id,
      sortable: false,
      // width: "200px",
      cell: (row) => {
        return (
          <div>
            <br />
            <p>Date: {moment(row.created_at).format("L")}</p>
            <br />
            <p>Name: {row.shipment.username}</p>
            <br />
            <p>Address: {row.shipment.address}</p>
            <br />
            <p>Phone: {row.shipment.phone}</p>
            <br />
            <p>Status: {row.status}</p>
            <br />
            <p>Access Code: {row.shipment.access_code}</p>
            <br />
            <p>Tracking Code: {row.shipment.track}</p>
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      // width: "450px",
      cell: (row) => {
        if (row.status === "shipping") {
          return (
            <div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginTop: "10px" }}
              >
                View More
              </button>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  attemptOrder(row?.id);
                }}
              >
                {isLoadingAttempt.loading && isLoadingAttempt.key === row.id
                  ? "Loading..."
                  : "Attempt"}
              </button>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  showMediumModal(row?.id);
                }}
              >
                Received Item
              </button>
            </div>
          );
        } else if (row.status === "delivered") {
          return (
            <div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginTop: "10px" }}
              >
                View More
              </button>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled
                style={{ marginTop: "10px" }}
              >
                Item Delivered
              </button>
            </div>
          );
        } else {
          return (
            <div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginTop: "10px" }}
              >
                View More
              </button>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => {
                  startRideHandle(row.id);
                }}
                style={{ marginTop: "10px" }}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Start Ride"}
              </button>
            </div>
          );
        }
      },
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getAllSchedules({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getAllSchedules({
        params: { page: page },
      })
    );
  };

  const handleSubmitSearch = () => {
    dispatch(
      getAllSchedules({
        params: {
          // status: status,
          // track: inputSearchValues.track_id,
          // search: inputSearchValues.name,
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        },
      })
    );
    // setInputSearchValues({
    //   name: "",
    //   track_id: "",
    // });
    // setStatus("");
    setStartDate(null);
    setEndDate(null)
  };

  //function to close edit pricing modal on success or using the cancel button on edit pricing modal
  function handleCloseModalOne() {
    document.getElementById("closeButtonOne").click();
  }

  function handleAccess() {
    setLoader(true);
    const dataNow = {
      access_code: accessCode,
    };
    return makeAPICall({
      path: `/rider/validate/recipient/${id}`,
      method: "PUT",
      payload: dataNow,
    })
      .then((res) => {
        setLoader(false);
        setAccessCode("");
        handleCloseModalOne();
        dispatch(getAllSchedules({}));
        toast("Trip has been verified", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoader(false);
        setAccessCode("");
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <RiderBackBone title="All Schedules">
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={allSchedules?.results}
              progressPending={loading}
              pagination
              title="Schedules"
              paginationServer
              paginationTotalRows={allSchedules?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            {/* <div className="col-md-3">
              <label className="form-label">Tracking Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="tracking code"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.track_id}
                name="track_id"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Rider Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.name}
                name="name"
                style={{ height: "45px" }}
              />
            </div> */}
            {/* <div className="col-md-3">
              <label className="form-label">Status</label>
              <select
                onChange={(e) => setStatus(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={status}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pending">Pending</option>
                <option value="shipping">Shipping</option>
                <option value="delivered">Delivered</option>
              </select>
            </div> */}
            <div className="col-md-3">
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    startDate ||
                    endDate
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        {/* hidden button to fire the drawer to show more order details*/}
        <button
          type="submit"
          className="btn btn-primary"
          id="showMod"
          data-bs-toggle="modal"
          data-bs-target="#modal-right"
          style={{ display: "none" }}
        >
          View More
        </button>

        {/* hidden button to open edit price modal */}
        <button
          type="submit"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#myModalOne"
          id="openModalBtn"
          style={{ display: "none" }}
        >
          Edit Hidden
        </button>

        {/* right drawer to see order details begin */}
        <RightModal title="More details">
          <>
            <h1 style={{ fontSize: "18px" }}>Schedules Information</h1>
            <hr />
            <div>
              <h1 style={{ fontSize: "16px" }}>Shipment Number</h1>
              <p style={{ fontSize: "14px" }}>{scheduleDetails?.id}</p>
              <h1 style={{ fontSize: "16px" }}>Shipment Status</h1>
              <p style={{ fontSize: "14px" }}>{scheduleDetails?.status}</p>
            </div>
            <hr />
            <h1 style={{ fontSize: "18px" }}>Order Information</h1>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Address</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.address}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Shipment Type</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.ship_type}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Landmark</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.landmark}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>City</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.city}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>State</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.state}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Country</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.country}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Description</h1>
                <p style={{ fontSize: "14px" }}>
                  {scheduleDetails?.description}
                </p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Weight</h1>
                <p style={{ fontSize: "14px" }}>{scheduleDetails?.weight}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Delivered</h1>
                <p style={{ fontSize: "14px" }}>
                  {scheduleDetails?.is_delivered ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Within Lagos?</h1>
                <p style={{ fontSize: "14px" }}>
                  {scheduleDetails?.is_managed ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </>
        </RightModal>
        {/* right drawer to see order details end */}

        {/* Modal to edit pricing */}
        <EditModal title="Verify Ride Complete">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Location</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g. 1234"
                      onChange={(e) => setAccessCode(e.target.value)}
                      value={accessCode}
                      name="location"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalOne}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!accessCode}
                onClick={handleAccess}
              >
                {loader ? (
                  "Loading..."
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Confirm Trip Complete
                  </span>
                )}
              </button>
            </div>
          </div>
        </EditModal>
        {/* Modal to edit pricing end */}
      </div>
    </RiderBackBone>
  );
}
