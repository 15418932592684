import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import makeAPICall from "../../utils/apiUtils";

const initialState = {
  loading: false,
  hasErrors: null,
  pricing: {},
};

export const DispatcherSlice = createSlice({
  name: "pricingList",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.pricing = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = DispatcherSlice.actions;

// A selector
export const getDispatcherSelector = (state) => state.pricingList;

// The reducer
export default DispatcherSlice.reducer;

// api call action
export const getDispatcher = ({params = null}) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/shipping/pricing/",
    params,
    method: "GET",
  })
    .then((res) => {
      dispatch(getAppSuccess(res));
    })
    .catch((err) => {
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAppFailure(err));
    });
};
