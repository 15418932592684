import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Accordion from "../../components/accordion/Accordion";
import { accordionData } from "../../components/accordion/data";
import "./services.css";

import CargoShippingIcon from "../../assets/images/cargoIcon.png";
import AirIcon from "../../assets/images/airIcon.png";
import TruckIcon from "../../assets/images/truckIcon.png";
import RoadIcon from "../../assets/images/roadIcon.png";
import WarehouseIcon from "../../assets/images/warehouseIcon.png";
import ServiceImg from "../../assets/images/servicePix.png";
import CardboxIconOne from "../../assets/images/packaging-parsell.png";
import CardboxIconTwo from "../../assets/images/drive.png";
import CardboxIconThree from "../../assets/images/security.png";
import CardboxIconFour from "../../assets/images/delivery.png";

const ServicesPage = () => {
  return (
    <>
      <section className="service-one">
        <Header />
        <div className="serviceHero-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Services</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="serviceRoundedBorder">
          <div className="container">
            <div className="row">
              <div className="service-box">
                <p>Services</p>
                <small>Home / Services</small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-two">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span>Work Process</span>
              <hr />
              <h3>We always committed to deliver your products</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="cardbox-one">
                <img src={CardboxIconOne} alt="" className="cardbox-icon" />
                <p>Packaging Item</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cardbox-two">
                <img src={CardboxIconTwo} alt="" className="cardbox-icon" />
                <p>Inventory Management</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cardbox-three">
                <img src={CardboxIconThree} alt="" className="cardbox-icon" />
                <p>Security Ensure</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cardbox-four">
                <img src={CardboxIconFour} alt="" className="cardbox-icon" />
                <p>Order Delivery</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-three">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <span>Services</span>
              <hr />
              <h3>What Services we offer for your logistics</h3>
              <p>
              We are committed to sustainability and how our industry is
                helping deliver a sustainable future for businesses
              </p>
            </div>
          </div>
          <div className="row row-grid">
            <div className="col-md-3">
              <div className="section-card">
                <img
                  src={CargoShippingIcon}
                  alt="card-img"
                  className="card-img"
                />
                <p className="card-head">Nationwide Delivery</p>
                <span>
                  We offer nationwide domestic deliveries to most major cities
                  within Nigeria no matter the size as this service allows for
                  items of various sizes, shapes and weight to be delivered
                  across the nation.
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={AirIcon} alt="card-img" className="card-img" />
                <p className="card-head">Worldwide Delivery</p>
                <span>
                  We operate express delivery to over 220 countries in the world
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={TruckIcon} alt="card-img" className="card-img" />
                <p className="card-head">Van/Truck Delivery</p>
                <span>
                  Our fleet of vans and trucks in sizes 3 tons, 3.5 tons, 10
                  tons, and 15 tons are available for heavy moving. With this
                  service we make Relocation of home or office simple and hassle
                  free. We can pick up from your location and move all items to
                  your new location with the highest professional standards
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={RoadIcon} alt="card-img" className="card-img" />
                <p className="card-head">Dispatch Bike Delivery</p>
                <span>
                  Fast and efficient pick up and delivery based on your
                  companies need. Whether as a Ecommerce vendor or corporate
                  entity
                </span>
              </div>
            </div>
          </div>
          <div className="row row-grid mt-3">
            <div className="col-md-3 mx-auto">
              <div className="section-card">
                <img src={WarehouseIcon} alt="card-img" className="card-img" />
                <p className="card-head">Warehouse</p>
                <span>
                We have a conducive  building  where your home items, office items, goods or raw materials  can be stored for a period  of time
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-four">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span>FAQ</span>
              <hr />
              <h3>Learn more about AIO Logistics Network LTD</h3>
              {/* <p>For  all relating questions, please contact the following  number, <br/> +2348022717162, +2348168399532, +2349024631063, +2348062964776</p> */}
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-md-7 mb-3">
              <ul className="accordion">
                {accordionData.map(({ heading, content, number }) => (
                  <Accordion
                    number={number}
                    heading={heading}
                    content={content}
                  />
                ))}
              </ul>
            </div>
            <div className="col-md-5 mb-3">
              <img src={ServiceImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="service-five">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9 trans">
              <h3>Are you looking for a Logistic Service?</h3>
              <p>AIO will always help you to transport your products and documents</p>
            </div>
            <div className="col-md-3">
              <button className="service-fiveBtn">Send Request</button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ServicesPage;
