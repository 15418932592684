import React from "react";

const ProgressBar = ({ bgcolor, progress, height }) => {
  const parentdiv = {
    height: height,
    width: "400px",
    backgroundColor: "#FFF5DA",
    borderRadius: 10,
    margin: 10,
  };

  const childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div style={parentdiv}>
      <div style={childdiv}>
        <span style={progresstext}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
