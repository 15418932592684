import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import history from "../../history";
import { AUTH_TOKEN, REDIRECT_URL } from "../../../utils/constants";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  user: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = loginSlice.actions;

// A selector
export const getLoginSelector = (state) => state.login;

// The reducer
export default loginSlice.reducer;

// api call action
export const loginUser = (data) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/auth/login/",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      // console.log(res, "login successful");
      // dispatch(getAppSuccess(res));
      if (
        (res.is_business === true || res.is_business === false) &&
        res.is_staff === false &&
        res.is_rider === false &&
        res.is_verified === true
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/vendor";
        history.push(redirectUrl);
        window.location.reload();
      } else if (
        (res.is_business === true || res.is_business === false) &&
        res.is_staff === false &&
        res.is_rider === true &&
        res.is_verified === true
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/rider";
        history.push(redirectUrl);
        window.location.reload();
      } else if (
        (res.is_business === true || res.is_business === false) &&
        res.is_staff === true &&
        res.is_rider === false &&
        res.is_verified === true
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/admin";
          console.log(res)
        history.push(redirectUrl);
        window.location.reload();
      }
      // window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
      // window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
      // const redirectUrl =
      //   window.sessionStorage.getItem(REDIRECT_URL) ?? "/admin";
      // history.push(redirectUrl);
      // window.location.reload();
    })
    .catch((err) => {
      // console.log(err)
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAppFailure(err));
    });
};
