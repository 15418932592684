import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import PriceModal from "../../../components/Modal/shippingPrice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export default function ShippingAdmin() {
  const [priceDetails, setPriceDetails] = useState();
  const [price, setPrice] = useState("");
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  function openPriceModal(row) {
    setPriceDetails(row);
    document.getElementById("openModalPriceNow").click();
  }

  function newOrders(params = null) {
    // console.log(shipmentId, "test");
    setLoading(true);
    return makeAPICall({
      path: `/shipping/admin/new/orders/`,
      method: "GET",
      params,
    })
      .then((res) => {
        setLoading(false);
        // console.log(res, 'orders');
        setOrders(res);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    newOrders();
  }, []);

  function handleCloseModalPrice() {
    document.getElementById("priceView").click();
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.username,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: false,
      width: "200px",
    },
    {
      name: "Tracking Number",
      selector: (row) => row?.track,
      sortable: false,
      width: "200px",
    },

    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "200px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => openPriceModal(row)}
              style={{ marginRight: "10px" }}
            >
              Set Order Price
            </button>
          </div>
        );
      },
    },
  ];

  function handlePageChange(page) {
    newOrders({
      params: { page: page },
    });
  }

  async function handlePerRowsChange(newPerPage, page) {
    newOrders({
      params: { page: page },
    });
  }

  function handleSubmitPrice() {
    // console.log(shipmentId, "test");
    setLoadingPrice(true);
    const dataNow = {
      amount: price,
      is_managed: priceDetails.is_managed ? "True" : "False",
    };
    return makeAPICall({
      path: `/shipping/admin/approve/amount/${priceDetails.id}`,
      method: "PATCH",
      payload: dataNow,
    })
      .then((res) => {
        setLoadingPrice(false);
        handleCloseModalPrice();
        newOrders({});
        toast("A price have been set", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoadingPrice(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handleSubmitSearch = () => {
    newOrders({
      params: {
        email: email,
        username: name,
        phone: phone,
        ship_type: transactionType,
        country: country,
        state: region,
      },
    });
    setName("");
    setEmail("");
    setTransactionType("");
    setCountry("");
    setRegion("");
    setPhone("");
  };

  return (
    <AdminBackBone title="Set Order Pricing">
      <div>
        <button
          type="submit"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal-price"
          id="openModalPriceNow"
          style={{ display: "none" }}
        >
          Set Price
        </button>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Ship Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={country}
                classes="selectDrop"
                onChange={(val) => setCountry(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">State</label>
              <RegionDropdown
                country={country}
                classes="selectDrop"
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    name ||
                    transactionType ||
                    email ||
                    phone ||
                    country ||
                    region
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={orders?.results}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={orders?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>

        <PriceModal title="Set Shipping Price">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g 1500"
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                      name="amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalPrice}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!price}
                onClick={handleSubmitPrice}
              >
                {loadingPrice ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Set Price
                  </span>
                )}
              </button>
            </div>
          </div>
        </PriceModal>
      </div>
    </AdminBackBone>
  );
}
