import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getVendorUserSelector,
  getVendorUsers,
} from "../../../services/slices/admin/vendor";
import ModalComponent from "../../../components/Modal";
import VendorModal from "../../../components/Modal/vendorModal";
import { toast } from "react-toastify";
import makeAPICall from "../../../utils/apiUtils";
import EditModal from "../../../components/Modal/editPrice";
import { formatMoney } from "../../../utils/helperFunctions";
import ReactPhoneInput from "react-phone-input-2";

export default function VendorUserAdmin() {
  const dispatch = useDispatch();
  const { vendorUsers, loading } = useSelector(getVendorUserSelector);
  const [ownerId, setOwnerId] = useState();
  const [loader, setLoader] = useState();
  const [queryId, setQueryId] = useState();
  const [loadingNow, setLoadingNow] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [amount, setAmount] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [inputSearchValues, setInputSearchValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    type: "",
  });
  const [inputValuesNew, setInputValuesNew] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });

  useEffect(() => {
    dispatch(getVendorUsers({}));
  }, [dispatch]);

  function handleChangeSearchInputNew(event) {
    setInputValuesNew({
      ...inputValuesNew,
      [event.target.name]: event.target.value,
    });
  }

  function openModal(row) {
    setOwnerId(row);
    document.getElementById("openModalNow").click();
  }

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleChangeSearchInput(event) {
    setInputSearchValues({
      ...inputSearchValues,
      [event.target.name]: event.target.value,
    });
  }

  //function to price details and fire up edit modal
  const showModal = (row) => {
    setQueryId(row.id);
    document.getElementById("openModalBtn").click();
  };

  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  function handleCloseModalOne() {
    document.getElementById("closeButtonOne").click();
  }

  const deletePrice = (row) => {
    setIsLoading({ loading: true, key: row?.id });
    const data = {
      is_verified: row?.is_verified ? "False" : "True",
    };
    return makeAPICall({
      path: `/auth/verify/${row?.id}`,
      method: "PATCH",
      payload: data,
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        dispatch(getVendorUsers({}));
        toast("Vendor suspended", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
      sortable: false,
      width: "200px",
      cell: (row) => (
        <p>
          {row.firstname}&nbsp;{row.lastname}
        </p>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      width: "250px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: false,
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => row?.wallet,
      sortable: false,
      cell: (row) => <p>₦{formatMoney(row?.wallet)}</p>,
    },
    {
      name: "Vendor Type",
      selector: (row) => row?.id,
      sortable: false,
      width: "150px",
      cell: (row) => {
        if (
          (row?.is_business || !row?.is_business) &&
          !row?.is_merchant &&
          !row?.is_ecommerce
        ) {
          return <p>Basic</p>;
        } else if (
          (row?.is_business || !row?.is_business) &&
          row?.is_merchant &&
          !row?.is_ecommerce
        ) {
          return <p>Merchant</p>;
        } else if (
          (row?.is_business || !row?.is_business) &&
          !row?.is_merchant &&
          row?.is_ecommerce
        ) {
          return <p>E-commerce</p>;
        } else {
          return <p>Basic</p>;
        }
      },
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "500px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginRight: "10px" }}
              onClick={() => openModal(row?.id)}
            >
              Fund
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginRight: "10px" }}
              onClick={() => {
                showModal(row);
              }}
            >
              Change Vendor Type
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => {
                deletePrice(row);
              }}
            >
              {isLoading.loading && isLoading.key === row?.id
                ? "Loading..."
                : row.is_verified
                ? "Suspend"
                : "Verify"}
            </button>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getVendorUsers({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getVendorUsers({
        params: { page: page },
      })
    );
  };

  function handleSubmit() {
    // console.log(shipmentId, "test");
    setLoadingNow(true);
    const dataNow = {
      amount: amount,
      owner: ownerId,
      type: transactionType.toUpperCase(),
    };
    return makeAPICall({
      path: "/wallet/admin/fund/",
      method: "POST",
      payload: dataNow,
    })
      .then((res) => {
        setLoadingNow(false);
        handleCloseModal();
        dispatch(getVendorUsers({}));
        toast("Successfully added balance to vendor wallet", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoadingNow(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handleSubmitBusiness = () => {
    setLoadingType(true);
    const data = {
      is_business: businessType === "basic" ? "True" : "False",
      is_merchant: businessType === "basic" ? "True" : "False",
      is_ecommerce: businessType === "commerce" ? "True" : "False",
    };
    return makeAPICall({
      path: `/auth/approve/business/${queryId}`,
      payload: data,
      method: "PATCH",
    })
      .then((res) => {
        setLoadingType(false);
        handleCloseModalOne();
        dispatch(getVendorUsers({}));
        toast("Vendor type has been changed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoadingType(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSubmitSearch = () => {
    dispatch(
      getVendorUsers({
        params: {
          firstname: inputSearchValues.firstName,
          lastname: inputSearchValues.lastName,
          email: inputSearchValues.email,
          phone: inputSearchValues.phone,
          type: inputSearchValues.type,
        },
      })
    );
    setInputSearchValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      type: "",
    });
  };

  function handleCloseModalNow() {
    document.getElementById("close-vendor").click();
  }

  function signUp() {
    setLoader(true);
    const plus = "+";
    const phoneNumber = plus.concat(phoneState);
    const data = {
      email: inputValuesNew.email,
      password: inputValuesNew.password,
      firstname: inputValuesNew.first_name,
      lastname: inputValuesNew.last_name,
      address: inputValuesNew.address,
      phone: phoneNumber,
      is_merchant: vendorType === "merchant" ? "True" : "False",
      is_ecommerce: vendorType === "ecommerce" ? "True" : "False",
    };

    return makeAPICall({
      path: "/auth/register/vendor/",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        setLoader(false);
        dispatch(getVendorUsers({}));
        handleCloseModalNow();
        toast("You have created a new vendor", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoader(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <AdminBackBone title="All Users">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal-vendor"
            >
              Add New Vendor
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by First Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.firstName}
                name="firstName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Last Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.lastName}
                name="lastName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by Email"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.email}
                name="email"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Phone"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.phone}
                name="phone"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Vendor Type</label>
              <select
                onChange={(e) =>
                  setInputValuesNew({
                    ...inputValuesNew,
                    type: e.target.value,
                  })
                }
                className="selectDrop"
                // name="riderSelect"
                value={inputSearchValues.type}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="basic">Basic</option>
                <option value="ecommerce">E-commerce</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    inputSearchValues.firstName ||
                    inputSearchValues.lastName ||
                    inputSearchValues.email ||
                    inputSearchValues.phone ||
                    inputSearchValues.type
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={vendorUsers?.results}
              progressPending={loading}
              pagination
              title="Vendors"
              paginationServer
              paginationTotalRows={vendorUsers?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            id="openModalNow"
            style={{ display: "none" }}
          >
            Add Funds
          </button>
          {/* hidden button to open edit price modal */}
          <button
            type="submit"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#myModalOne"
            id="openModalBtn"
            style={{ display: "none" }}
          >
            Edit Hidden
          </button>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal-price"
          id="openModalPriceNow"
          style={{ display: "none" }}
        >
          Create Order
        </button>

        <ModalComponent title="Fund Vendor">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g 1500"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      name="amount"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>Transaction Type</h5>
                  <select
                    onChange={(e) => setTransactionType(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={transactionType}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="credit">Credit</option>
                    <option value="debit">Debit</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!transactionType}
              >
                {loadingNow ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Fund Vendor Account
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
        {/* Modal to edit pricing */}
        <EditModal title="Change Vendor Type">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="my-10">Vendor Business Type</h5>
                  <select
                    onChange={(e) => setBusinessType(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={businessType}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="commerce">E-commerce</option>
                    <option value="basic">Basic</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalOne}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmitBusiness}
                disabled={!businessType}
              >
                {loadingType ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Change Business Type
                  </span>
                )}
              </button>
            </div>
          </div>
        </EditModal>
        {/* Modal to edit pricing end */}

        <VendorModal title="Create New Vendor">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={inputValuesNew.first_name}
                      onChange={handleChangeSearchInputNew}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={inputValuesNew.last_name}
                      onChange={handleChangeSearchInputNew}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={inputValuesNew.email}
                      onChange={handleChangeSearchInputNew}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      value={inputValuesNew.address}
                      onChange={handleChangeSearchInputNew}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="password"
                      value={inputValuesNew.password}
                      onChange={handleChangeSearchInputNew}
                      name="password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={phoneState}
                      onChange={handleOnChangePhoneInput}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Select Vendor Type</label>
                    <select
                      onChange={(e) => setVendorType(e.target.value)}
                      className="selectDrop"
                      // name="riderSelect"
                      value={vendorType}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="basic">Basic</option>
                      <option value="ecommerce">E-commerce</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalNow}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                disabled={
                  !(
                    inputValuesNew.password &&
                    inputValuesNew.email &&
                    inputValuesNew.first_name &&
                    inputValuesNew.last_name &&
                    phoneState &&
                    inputValuesNew.address &&
                    vendorType
                  )
                }
                className="btn btn-primary"
                onClick={signUp}
              >
                {loader ? (
                  "Loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Create
                  </span>
                )}
              </button>
            </div>
          </div>
        </VendorModal>
      </div>
    </AdminBackBone>
  );
}
