import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  pricing: {},
};

export const PricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.pricing = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = PricingSlice.actions;

// A selector
export const getPricingSelector = (state) => state.pricing;

// The reducer
export default PricingSlice.reducer;

// api call action
export const getPricing = ({ params = null }) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/shipping/admin/pricing/",
    params,
    method: "GET",
  })
    .then((res) => {
      dispatch(getAppSuccess(res));
    })
    .catch((err) => {
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAppFailure(err));
    });
};
