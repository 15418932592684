import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import "./about.css";

import CargoShippingIcon from "../../assets/images/cargoIcon.png";
import AirIcon from "../../assets/images/airIcon.png";
import TruckIcon from "../../assets/images/truckIcon.png";
import RoadIcon from "../../assets/images/roadIcon.png";
import AwardImg from "../../assets/images/award.png";
import AboutImg from "../../assets/images/aboutPix.png";
import YellowAngle from "../../assets/images/paleAngle.png";
// import TeamOne from "../../assets/images/teamMem1.png";
// import TeamTwo from "../../assets/images/teamMem2.png";
// import TeamThree from "../../assets/images/teamMem3.png";
// import FBIcon from "../../assets/images/facebookIcon.png";
// import IGIcon from "../../assets/images/instagramIcon.png";
// import LKIcon from "../../assets/images/linkedinIcon.png";

const About = () => {
  return (
    <>
      <section className="about-one">
        <Header />
        <div className="aboutHero-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>About us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="aboutRoundedBorder">
          <div className="container">
            <div className="row">
              <div className="about-box">
                <p>About Us</p>
                <small>Home / About Us</small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-two">
        <div className="container">
          <div className="row">
            <div id="container" className="col-md-6">
              <img src={YellowAngle} alt="" className="yellowImg" />
              <div className="ad-image">
                <img id="bottom" src={AboutImg} alt="" />
              </div>
              <img id="top" src={YellowAngle} alt="" className="yellowImg" />
            </div>
            <div className="col-md-6" id="column">
              <span>About Us</span>
              <hr />
              <h3>Digital & Trusted Logistics Company</h3>
              <p>
              AIO Logistics Network Limited (RC NO: 1885123) is a courier and logistics services company that offers the most reliable package delivery service Nationwide, International shipping and highest quality warehousing for a range of purposes. 


              </p>
              <div className="award-box">
                <img src={AwardImg} alt="" className="award-img" />
                <div>
                  <p className="totalCount">100+</p>
                  <p>Award Wining Company</p>
                </div>
              </div>
              <p>
              AIO Logistics Network Limited is a proud recipient of prestigious awards for outstanding achievement in sales and customer service – a testament to the customer-centric mindset at the heart of every shipment we deliver.
              </p>
              <p>
              As a global company based in Lagos Nigeria, AIO Logistics Network Limited is exceptionally positioned to serve both international and domestic customers in the world’s fastest growing markets. We provide advanced solutions through our value-creating services that will allow companies to make the right moves for their business. Our experienced and dedicated professionals provide companies with international/domestic shipping and logistics solutions.
              </p>
              {/* <button className="about-twoBtn">Know More</button> */}
            </div>
          </div>
        </div>
      </section>

      <section className="about-three">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>We are Optimists who Love to Work Together</h3>
              <p>
                We will take care of your cargo or your passenger and deliver
                them safe and on time
              </p>
            </div>
          </div>
          <div className="row row-grid">
            <div className="col-md-3">
              <div className="section-card">
                <img
                  src={CargoShippingIcon}
                  alt="card-img"
                  className="card-img"
                />
                <p className="card-head">Nationwide Delivery</p>
                <span>
                  We offer nationwide domestic deliveries to most major cities
                  within Nigeria no matter the size as this service allows for
                  items of various sizes, shapes and weight to be delivered
                  across the nation.
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={AirIcon} alt="card-img" className="card-img" />
                <p className="card-head">Worldwide Delivery</p>
                <span>
                  We operate express delivery to over 220 countries in the world
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={TruckIcon} alt="card-img" className="card-img" />
                <p className="card-head">Van/Truck Delivery</p>
                <span>
                  Our fleet of vans and trucks in sizes 3 tons, 3.5 tons, 10
                  tons, and 15 tons are available for heavy moving. With this
                  service we make Relocation of home or office simple and hassle
                  free. We can pick up from your location and move all items to
                  your new location with the highest professional standards
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="section-card">
                <img src={RoadIcon} alt="card-img" className="card-img" />
                <p className="card-head">Dispatch Bike Delivery</p>
                <span>
                  Fast and efficient pick up and delivery based on your
                  companies need. Whether as a Ecommerce vendor or corporate
                  entity
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center" style={{ marginTop: 30 }}>
              <Link to="/services" className="more-btn">
                See All
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about-four">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span>Team Members</span>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Our Skilled Member</h3>
                <Link href="/" className="more-team">
                  View All Member
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="team-card">
                <img src={TeamOne} alt="" />
              </div>
              <div className="team-border">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Samuel Khan</p>
                  <div>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={FBIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={IGIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={LKIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                  </div>
                </div>
                <small>CEO</small>
              </div>
            </div>

            <div className="col-md-3">
              <div className="team-card">
                <img src={TeamTwo} alt="" />
              </div>
              <div className="team-border">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>James Shams</p>
                  <div>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={FBIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={IGIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={LKIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                  </div>
                </div>
                <small>Vice President</small>
              </div>
            </div>

            <div className="col-md-3">
              <div className="team-card">
                <img src={TeamThree} alt="" />
              </div>
              <div className="team-border">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Abdur Razzak</p>
                  <div>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={FBIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={IGIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={LKIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                  </div>
                </div>
                <small>Designer</small>
              </div>
            </div>

            <div className="col-md-3">
              <div className="team-card">
                <img src={TeamOne} alt="" />
              </div>
              <div className="team-border">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Rodrigo Zaknal</p>
                  <div>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={FBIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={IGIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="team-social-icons"
                    >
                      <img src={LKIcon} alt="" style={{ marginRight: 5 }} />
                    </a>
                  </div>
                </div>
                <small>Developer</small>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default About;
