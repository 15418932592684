import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { useSelector, useDispatch } from "react-redux";
import { getPricing } from "../../../services/slices/admin/pricing";
import { getRiders } from "../../../services/slices/admin/riders";
import {
  getSummarySelector,
  summaryTransactions,
} from "../../../services/slices/admin/summary";
import moment from "moment";
import DatePicker from "react-datepicker";
import { formatMoney } from "../../../utils/helperFunctions";

export default function SuperAdmin() {
  const dispatch = useDispatch();
  const { loading: loadSummary, summary } = useSelector(getSummarySelector);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);

  // console.log(summary, "trans summary");

  useEffect(() => {
    dispatch(getPricing({}));
    dispatch(getRiders({}));
    dispatch(summaryTransactions({}));
  }, [dispatch]);

  const handleSubmitRange = () => {
    dispatch(
      summaryTransactions({
        params: {
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        },
      })
    );
    setStartDate(null);
    setEndDate(null)
  };

  return (
    <AdminBackBone title="Overview">
      <div>
        <div className="row">
          <div className="col-md-3">
            <label className="form-label">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">&nbsp;</label>
            <br />
            <button
              type="submit"
              className="btn btn-danger"
              disabled={!(startDate && endDate)}
              onClick={handleSubmitRange}
            >
              {loadSummary ? "Fetching..." : "Search For Transaction Summary"}
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <h1 style={{ fontSize: "16px" }}>Wallet Analytics</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary
                      ? "Loading..."
                      : formatMoney(summary?.totalCredit)}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-money"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Credit</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary
                      ? "Loading..."
                      : formatMoney(summary?.totalDebit)}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-money"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Debit</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary
                      ? "Loading..."
                      : formatMoney(summary?.vendorFunded)}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-money"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Vendor Fund</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.totalTransactions}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-exchange"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Transactions</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <h1 style={{ fontSize: "16px" }}>User Analytics</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.totalUsers}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Users</p>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.totalSchedules}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-calendar"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Schedules</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h1 style={{ fontSize: "16px" }}>Order Analytics</h1>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.totalOrders}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Orders</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.totalDelivered}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Orders Delivered</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.pending}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Orders Pending</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h2 className="my-0 fw-600 text-primary">
                    {loadSummary ? "Loading..." : summary?.openOrders}
                  </h2>
                  <div className="w-40 h-40 bg-primary rounded-circle text-center fs-24 l-h-40">
                    <i className="fa fa-shopping-cart"></i>
                  </div>
                </div>
                <p className="fs-18 mt-10">Total Open Orders</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
