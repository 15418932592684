/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../../assets/images/aioWhiteImg.png";
import avatar from "../../assets/images/avatar-1.png";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../utils/authUtils";
import "./admin.css";

export default function AdminBackBone({ title, children }) {
  const [showOthers, setShowOthers] = useState(false);

  const handleShow = () => {
    setShowOthers(!showOthers);
  };
  return (
    <div className="hold-transition light-skin sidebar-mini theme-primary fixed">
      <div className="wrapper">
        {/* <div id="loader"></div> */}

        <header className="main-header">
          <div className="d-flex align-items-center logo-box justify-content-start">
            <Link to="/admin" className="logo">
              <div className="logo-mini w-50">
                <span className="light-logo">
                  <img src={logo} alt="logo" />
                </span>
                <span className="dark-logo">
                  <img src={logo} alt="logo" />
                </span>
              </div>
              <div className="logo-lg">
                <span
                  className="light-logo"
                  style={{
                    fontSize: "40px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AIO
                </span>
                <span
                  className="dark-logo"
                  style={{
                    fontSize: "40px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AIO
                </span>
              </div>
            </Link>
          </div>
          <nav className="navbar navbar-static-top">
            <div className="app-menu">
              <ul className="header-megamenu nav">
                <li className="btn-group nav-item">
                  <a
                    href="#"
                    className="waves-effect waves-light nav-link push-btn btn-primary-light"
                    data-toggle="push-menu"
                    role="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-align-left"
                    >
                      <line x1="17" y1="10" x2="3" y2="10"></line>
                      <line x1="21" y1="6" x2="3" y2="6"></line>
                      <line x1="21" y1="14" x2="3" y2="14"></line>
                      <line x1="17" y1="18" x2="3" y2="18"></line>
                    </svg>
                  </a>
                </li>
                <li className="btn-group d-lg-inline-flex d-none">
                  <div className="app-menu">
                    <div className="search-bx mx-5">
                      <form>
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="button-addon3"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn"
                              type="submit"
                              id="button-addon3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-search"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="navbar-custom-menu r-side">
              <ul className="nav navbar-nav">
                <li className="dropdown user user-menu">
                  <a
                    href="#"
                    className="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent py-0 no-shadow"
                    data-bs-toggle="dropdown"
                    title="User"
                  >
                    <img
                      src={avatar}
                      className="avatar rounded-10 bg-primary-light h-40 w-40"
                      alt=""
                    />
                  </a>
                  <ul className="dropdown-menu animated flipInX">
                    <li className="user-body">
                      {/* <Link to="/profile" className="dropdown-item">
                        <i className="ti-user text-muted me-2"></i> Profile
                      </Link> */}
                      <Link className="dropdown-item" to="/settings">
                        <i className="ti-settings text-muted me-2"></i> Settings
                      </Link>
                      <div className="dropdown-divider"></div>
                      <p
                        className="dropdown-item"
                        onClick={logout}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="ti-lock text-muted me-2"></i> Logout
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <aside className="main-sidebar">
          <section className="sidebar position-relative">
            <div className="multinav">
              <div className="multinav-scroll" style={{ height: "100%" }}>
                <ul className="sidebar-menu" data-widget="tree">
                  <li className="tester">
                    <NavLink
                      to="/admin"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-monitor"
                      >
                        <rect
                          x="2"
                          y="3"
                          width="20"
                          height="14"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="8" y1="21" x2="16" y2="21"></line>
                        <line x1="12" y1="17" x2="12" y2="21"></line>
                      </svg>
                      <span>Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="tester">
                    <NavLink
                      to="/admin/users"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                      <span>Users</span>
                    </NavLink>
                  </li>
                  <li className="tester">
                    <NavLink
                      to="/admin/admin"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                      <span>Admins</span>
                    </NavLink>
                  </li>

                  <li className="tester">
                    <NavLink
                      to="/admin/vendors"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                      <span>Vendors</span>
                    </NavLink>
                  </li>
                  <li className="tester">
                    <NavLink
                      to="/admin/transactions"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-activity"
                      >
                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                      </svg>
                      <span>Transactions</span>
                    </NavLink>
                  </li>
                  <button className="dropdown-btn" onClick={handleShow}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-box"
                    >
                      <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                      <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                      <line x1="12" y1="22.76" x2="12" y2="11"></line>
                    </svg>
                    <span>Orders</span>
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div
                    className={` ${
                      showOthers
                        ? "dropdown-container-show "
                        : "dropdown-container"
                    }`}
                  >
                    <p className="sub-i">
                      <NavLink
                        to="/admin/orders"
                        // className={({ isActive }) =>
                        //   isActive ? "active" : ""
                        // }
                      >
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        All Orders
                      </NavLink>
                    </p>
                    <p className="sub-i">
                      <NavLink
                        to="/admin/orders/assign"
                        // className={({ isActive }) =>
                        //   isActive ? "active" : ""
                        // }
                      >
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Assign Rider For Orders
                      </NavLink>
                    </p>
                    <p className="sub-i">
                      {" "}
                      <NavLink
                        to="/admin/orders/set-shipping-fee"
                        // className={({ isActive }) =>
                        //   isActive ? "active" : ""
                        // }
                      >
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Set Order Price
                      </NavLink>
                    </p>
                    <p className="sub-i">
                      <NavLink to="/admin/orders/pay">
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Pay Order
                      </NavLink>
                    </p>
                  </div>
                  
                  {/* <li>
                    <NavLink
                      to="/admin/orders"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-box"
                      >
                        <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                        <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                        <line x1="12" y1="22.76" x2="12" y2="11"></line>
                      </svg>
                      <span>Orders</span>
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      to="/admin/assign"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-truck"
                      >
                        <rect x="1" y="3" width="15" height="13"></rect>
                        <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                        <circle cx="5.5" cy="18.5" r="2.5"></circle>
                        <circle cx="18.5" cy="18.5" r="2.5"></circle>
                      </svg>
                      <span>Assign Rider For Orders</span>
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      to="/admin/set-shipping-fee"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-box"
                      >
                        <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                        <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                        <line x1="12" y1="22.76" x2="12" y2="11"></line>
                      </svg>
                      <span>Set Order Price</span>
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      to="/admin/pay"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                      <span>Pay Order</span>
                    </NavLink>
                  </li> */}
                  <li className="tester">
                    <NavLink
                      to="/admin/pricing"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                      <span>Pricing</span>
                    </NavLink>
                  </li>
                  <li className="tester">
                    <NavLink
                      to="/admin/riders"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-truck"
                      >
                        <rect x="1" y="3" width="15" height="13"></rect>
                        <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                        <circle cx="5.5" cy="18.5" r="2.5"></circle>
                        <circle cx="18.5" cy="18.5" r="2.5"></circle>
                      </svg>
                      <span>Riders</span>
                    </NavLink>
                  </li>
                  <li className="tester">
                    <NavLink
                      to="/admin/schedules"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-calendar"
                      >
                        <rect
                          x="3"
                          y="4"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                      <span>Schedules</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-settings"
                      >
                        <circle cx="12" cy="12" r="3"></circle>
                        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                      </svg>
                      <span>Settings</span>
                    </a>
                  </li> */}
                </ul>

                {/* <div className="sidebar-widgets">
                  <div className="copyright text-center m-25 text-white-50">
                    <p>
                      <strong className="d-block">AIO Logistics</strong>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </aside>

        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h4 className="page-title">{title}</h4>
                </div>
              </div>
            </div>
            <section className="content">{children}</section>
          </div>
        </div>

        <div className="control-sidebar-bg"></div>
      </div>
    </div>
  );
}
