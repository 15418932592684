import React from "react";

export default function EditModal({ title, children }) {
  return (
    <div
      id="myModalOne"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalOneLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalOneLabel">
              {title}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeButtonOne"
            ></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
}
