import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { useSelector, useDispatch } from "react-redux";
import {
  getPricingSelector,
  getPricing,
} from "../../../services/slices/admin/pricing";
import DataTable from "react-data-table-component";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import ModalComponent from "../../../components/Modal";
import EditModal from "../../../components/Modal/editPrice";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

export default function PricingAdmin() {
  const dispatch = useDispatch();
  const { pricing, loading: loader } = useSelector(getPricingSelector);
  const [inputValues, setInputValues] = useState({
    location: "",
    amount: "",
  });
  const [editInputValues, setEditInputValues] = useState({
    location: "",
    amount: "",
    id: "",
  });
  const [coverage, setCoverage] = useState("");
  const [logisticsType, setLogisticsType] = useState("");
  const [logisticsTypeEdit, setLogisticsTypeEdit] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [vendorTypeEdit, setVendorTypeEdit] = useState("");
  const [coverageEdit, setCoverageEdit] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [isLoadingEdit, setIsLoadingEdit] = useState({
    loading: false,
    key: 0,
  });
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    dispatch(getPricing({}))
   }, [dispatch]);


  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleChange = (e) => {
    setCoverage(e.target.value);
  };

  const handleChangeEdit = (e) => {
    setCoverageEdit(e.target.value);
  };

  function handleChangeInputEdit(event) {
    setEditInputValues({
      ...editInputValues,
      [event.target.name]: event.target.value,
    });
  }

  //function to price details and fire up edit modal
  const showModal = (row) => {
    setEditInputValues({
      location: row?.location,
      amount: row?.amount,
      id: row.id,
    });
    setCoverageEdit(row?.coverage);
    setLogisticsTypeEdit(row?.logistic_type)
    setVendorTypeEdit(row?.vendor_type)
    document.getElementById("openModalBtn").click();
  };

  const deletePrice = (id) => {
    setIsLoading({ loading: true, key: id });
    return makeAPICall({
      path: `/shipping/admin/pricing/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        dispatch(getPricing({}));
        toast("Price deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row?.created_at,
      sortable: false,
      cell: (row) => {
        return <p>{moment(row?.created_at).format("L")}</p>;
      },
    },
    {
      name: "Location",
      selector: (row) => row?.location,
      sortable: false,
    },
    {
      name: "Coverage Area",
      selector: (row) => row?.coverage,
      sortable: false,
    },
    {
      name: "Logistics Type",
      selector: (row) => row?.logistic_type,
      sortable: false,
      cell: (row) => {
        if (row.logistic_type === "warehouse") {
          return <p>Self Pickup</p>;
        } else {
          return <p>{row.logistic_type}</p>;
        }
      },
    },
    {
      name: "Vendor Type",
      selector: (row) => row?.vendor_type,
      sortable: false,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: false,
      cell: (row) => {
        return <p>₦{formatMoney(row?.amount)}</p>;
      },
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "200px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginRight: "10px" }}
              onClick={() => {
                showModal(row);
              }}
            >
              {isLoadingEdit.loading && isLoadingEdit.key === row.id
                ? "Loading..."
                : "Edit"}
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => {
                deletePrice(row.id);
              }}
            >
              {isLoading.loading && isLoading.key === row.id
                ? "Loading..."
                : "Delete"}
            </button>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    dispatch(
      getPricing({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getPricing({
        params: { page: page },
      })
    );
  };

  //function to close modal on success or using the cancel button on new pricing modal
  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  //function to close edit pricing modal on success or using the cancel button on edit pricing modal
  function handleCloseModalOne() {
    document.getElementById("closeButtonOne").click();
  }

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      amount: inputValues.amount,
      location: inputValues.location,
      coverage: coverage,
      logistic_type: logisticsType,
      vendor_type: vendorType,
    };
    return makeAPICall({
      path: "/shipping/admin/pricing/",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        dispatch(getPricing({}));
        setInputValues({
          location: "",
          amount: "",
        });
        toast("Price has been added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSubmitEdit = () => {
    setIsLoadingEdit({ loading: true, key: editInputValues.id });
    const data = {
      amount: editInputValues.amount,
      location: editInputValues.location,
      coverage: coverageEdit,
      logistic_type: logisticsTypeEdit,
      vendor_type: vendorTypeEdit,
    };
    return makeAPICall({
      path: `/shipping/admin/pricing/${editInputValues.id}`,
      payload: data,
      method: "PUT",
    })
      .then((res) => {
        setIsLoadingEdit({ loading: false, key: 0 });
        handleCloseModalOne();
        dispatch(getPricing({}));
        setEditInputValues({
          location: "",
          amount: "",
        });
        toast("Price has been updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoadingEdit({ loading: false, key: 0 });
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSubmitPriceFilter = () => {
    dispatch(
      getPricing({
        params: { search: name, logistic_type: type },
      })
    );
    setName("");
  };

  return (
    <AdminBackBone title="Pricing">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-end">
            {/* button to create new pricing */}
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              Add New Pricing
            </button>

            {/* hidden button to open edit price modal */}
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModalOne"
              id="openModalBtn"
              style={{ display: "none" }}
            >
              Edit Hidden
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Enter Search Term</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by location or coverage"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Logistic Type</label>
              <select
                onChange={(e) => setType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={type}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="bike">Bike</option>
                <option value="van">Van</option>
                <option value="warehouse">Self Pickup</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={!(name || type)}
                onClick={handleSubmitPriceFilter}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={pricing?.results}
              progressPending={loader}
              pagination
              title="Pricing"
              paginationServer
              paginationTotalRows={pricing?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>

        {/* Modal to create a new pricing */}
        <ModalComponent title="Add New Pricing">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g Ikeja"
                      onChange={handleChangeInput}
                      value={inputValues.location}
                      name="location"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g 1500"
                      onChange={handleChangeInput}
                      value={inputValues.amount}
                      name="amount"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="my-10">Select Coverage</h5>
                  <select
                    onChange={handleChange}
                    className="selectDrop"
                    // name="riderSelect"
                    value={coverage}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="lagos">Lagos</option>
                    <option value="outside lagos">Outside Lagos</option>
                    <option value="international">International</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <h5 className="my-10">Logistic Type</h5>
                  <select
                    onChange={(e) => setLogisticsType(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={logisticsType}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="bike">Bike</option>
                    <option value="van">Van</option>
                    <option value="warehouse">Self Pickup</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="my-10">Vendor Type</h5>
                  <select
                    onChange={(e) => setVendorType(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={vendorType}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="basic">Basic</option>
                    <option value="merchant">Merchant</option>
                    <option value="ecommerce">E-Commerce</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !(
                    inputValues.location &&
                    inputValues.amount &&
                    coverage &&
                    logisticsType && vendorType
                  )
                }
                onClick={handleSubmit}
              >
                {loading ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Create New Price
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
        {/* Modal to create a new pricing end */}

        {/* Modal to edit pricing */}
        <EditModal title="Edit Pricing">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g Ikeja"
                      onChange={handleChangeInputEdit}
                      value={editInputValues.location}
                      name="location"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="e.g 1500"
                      onChange={handleChangeInputEdit}
                      value={editInputValues.amount}
                      name="amount"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="my-10">Select Coverage</h5>
                  <select
                    onChange={handleChangeEdit}
                    className="selectDrop"
                    // name="riderSelect"
                    value={coverageEdit}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="lagos">Lagos</option>
                    <option value="outside lagos">Outside Lagos</option>
                    <option value="international">International</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <h5 className="my-10">Logistic Type</h5>
                  <select
                    onChange={(e) => setLogisticsTypeEdit(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={logisticsTypeEdit}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="bike">Bike</option>
                    <option value="van">Van</option>
                    <option value="warehouse">Self Pickup</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="my-10">Vendor Type</h5>
                  <select
                    onChange={(e) => setVendorTypeEdit(e.target.value)}
                    className="selectDrop"
                    // name="riderSelect"
                    value={vendorTypeEdit}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    <option value="basic">Basic</option>
                    <option value="merchant">Merchant</option>
                    <option value="ecommerce">E-Commerce</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalOne}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !(
                    editInputValues.location &&
                    editInputValues.amount &&
                    coverageEdit &&
                    logisticsTypeEdit && vendorTypeEdit
                  )
                }
                onClick={handleSubmitEdit}
              >
                {loading ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Edit Price
                  </span>
                )}
              </button>
            </div>
          </div>
        </EditModal>
        {/* Modal to edit pricing end */}
      </div>
    </AdminBackBone>
  );
}
