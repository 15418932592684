import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getRiderSelector,
  getRiders,
} from "../../../services/slices/admin/riders";
import {
  getRidersAccountSelector,
  getRidersAccount,
} from "../../../services/slices/admin/createRiders";
import ModalComponent from "../../../components/Modal";
import PriceModal from "../../../components/Modal/shippingPrice";
import ReactPhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import makeAPICall from "../../../utils/apiUtils";

export default function RiderAdmin() {
  const dispatch = useDispatch();
  const { riders, loading } = useSelector(getRiderSelector);
  const { ridersAccount, loading: loader } = useSelector(
    getRidersAccountSelector
  );
  const [phoneState, setPhoneState] = useState("");
  const [loaderReset, setLoaderReset] = useState("");
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    password: "",
  });
  const [inputResetValues, setInputResetValues] = useState({
    email: "",
    password: "",
  });
  const [inputSearchValues, setInputSearchValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    dispatch(getRiders({}))
   }, [dispatch]);

  function handleChangeSearchInput(event) {
    setInputSearchValues({
      ...inputSearchValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleChangeResetInput(event) {
    setInputResetValues({
      ...inputResetValues,
      [event.target.name]: event.target.value,
    });
  }

  const openPriceModal = (row) => {
    setInputResetValues({ email: row });
    document.getElementById("openModalPriceNow").click();
  };

  function signUp() {
    const plus = "+";
    const phoneNumber = plus.concat(phoneState);
    const data = {
      email: inputValues.email,
      password: inputValues.password,
      firstname: inputValues.first_name,
      lastname: inputValues.last_name,
      address: inputValues.address,
      phone: phoneNumber,
      is_verified: "True",
      is_rider: "True",
    };
    dispatch(getRidersAccount(data));
  }

  useEffect(() => {
    if (
      ridersAccount &&
      Object.keys(ridersAccount).length === 0 &&
      Object.getPrototypeOf(ridersAccount) === Object.prototype
    ) {
      console.log("no");
    } else {
      toast("Rider Added Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      handleCloseModal();
      dispatch(getRiders({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ridersAccount, dispatch]);

  const handlePageChange = (page) => {
    dispatch(
      getRiders({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getRiders({
        params: { page: page },
      })
    );
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
      sortable: false,
      cell: (row) => (
        <p>
          {row.firstname}&nbsp;{row.lastname}
        </p>
      ),
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <button
          type="submit"
          className="btn btn-danger"
          onClick={() => openPriceModal(row?.email)}
        >
          Reset Password
        </button>
      ),
    },
  ];

  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  function handleCloseModalPrice() {
    document.getElementById("priceView").click();
  }

  const handleSubmitPrice = () => {
    // console.log(shipmentId, "test");
    setLoaderReset(true);
    const dataNow = {
      email: inputResetValues.email,
      password: inputResetValues.password,
    };
    return makeAPICall({
      path: `/auth/reset/rider/`,
      method: "PATCH",
      payload: dataNow,
    })
      .then((res) => {
        setLoaderReset(false);
        handleCloseModalPrice();
        toast("Password have been reset", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoaderReset(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSubmitSearch = () => {
    dispatch(
      getRiders({
        params: {
          firstname: inputSearchValues.firstName,
          lastname: inputSearchValues.lastName,
          email: inputSearchValues.email,
          phone: inputSearchValues.phone,
        },
      })
    );
    setInputSearchValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
  };

  return (
    <AdminBackBone title="Riders">
      <div>
     
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              Add New Rider
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal-price"
              id="openModalPriceNow"
              style={{ display: "none" }}
            >
              Set Price
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by First Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.firstName}
                name="firstName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Last Name"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.lastName}
                name="lastName"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by Email"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.email}
                name="email"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Phone"
                onChange={handleChangeSearchInput}
                value={inputSearchValues.phone}
                name="phone"
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    inputSearchValues.firstName ||
                    inputSearchValues.lastName ||
                    inputSearchValues.email ||
                    inputSearchValues.phone
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={riders?.results}
              progressPending={loading}
              pagination
              title="Riders"
              paginationServer
              paginationTotalRows={riders?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <PriceModal title="Reset Password">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Username"
                      value={inputResetValues.email}
                      onChange={handleChangeResetInput}
                      name="email"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control ps-15 bg-transparent"
                      placeholder="Password"
                      value={inputResetValues.password}
                      onChange={handleChangeResetInput}
                      name="password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModalPrice}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !(inputResetValues.email && inputResetValues.password)
                }
                onClick={handleSubmitPrice}
              >
                {loaderReset ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Change Password
                  </span>
                )}
              </button>
            </div>
          </div>
        </PriceModal>
        <ModalComponent title="Assign Rider">
          <div className="form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={inputValues.first_name}
                      onChange={handleChangeInput}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={inputValues.last_name}
                      onChange={handleChangeInput}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={inputValues.email}
                      onChange={handleChangeInput}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      value={inputValues.address}
                      onChange={handleChangeInput}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="password"
                      value={inputValues.password}
                      onChange={handleChangeInput}
                      name="password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={phoneState}
                      onChange={handleOnChangePhoneInput}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                disabled={
                  !(
                    inputValues.password &&
                    inputValues.email &&
                    inputValues.first_name &&
                    inputValues.last_name &&
                    phoneState &&
                    inputValues.address
                  )
                }
                className="btn btn-primary"
                onClick={signUp}
              >
                {loader ? (
                  "Loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Save
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </AdminBackBone>
  );
}
