import React from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";

import AioLogo from "../../assets/images/aioWhiteImg.png";

const Navbar = () => {
  return (
    <>
      <header className="top-bar">
        <div className="topbar">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 text-md-start text-center">
                <div className="topbar-social">
                  <ul className="list-inline">
                    <li className="list-icons">
                      <a href="/">
                        <i className="text-white fa fa-phone"></i>{" "}
                        +2348022717162, +2348168399532
                      </a>
                    </li>
                    <li className="ms-10 pe-10">
                      <a href="https://www.instagram.com/aiologistics/?hl=en"
                        target="_blank"
                        rel="noreferrer">
                        <i className="text-white fa fa-instagram"></i>{" "}
                        aiologistics
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mt-md-0 mt-20 ">
                <div className="topbar-social ">
                  <ul className="list-inline d-flex gap-items-1 justify-content-md-end justify-content-center">
                    <li className="list-icons">
                      <a
                        href="https://web.facebook.com/aiologistics/?_rdc=1&_rdr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="list-icons">
                      <a
                        href="https://www.linkedin.com/in/aio-logistics-network-ltd-133b97268/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="list-icons">
                      <a
                        href="https://twitter.com/aio_logistics?lang=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-icons">
                      <a
                        href="https://www.instagram.com/aiologistics/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg navbar-light nav-bg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={AioLogo} className=" nav-logo" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link mx-2 active"
                  data-toggle="dropdown"
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className="nav-link mx-2"
                  aria-current="page"
                  to="/about"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link mx-2"
                  data-toggle="dropdown"
                  to="/services"
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link mx-2"
                  data-toggle="dropdown"
                  to="/track"
                >
                  Track
                </NavLink>
              </li>
              {/* <li className="nav-item ">
                <NavLink
                  className="nav-link mx-2"
                  aria-current="page"
                  to="/pricing"
                >
                  Pricing
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link mx-2" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            <br />
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <NavLink className="nav-btn-1 mx-2" to="/login">
                  Login
                </NavLink>
              </li>
              <br />
              <li className="nav-item">
                <NavLink className="nav-btn mx-2" to="/signup">
                  Sign up
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
