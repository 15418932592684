import React, { useState } from "react";
import BackBone from "../../../components/BackBone";
import DataTable from "react-data-table-component";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderVendorSelector,
  getOrdersVendor,
} from "../../../services/slices/vendor/orders";
import RightModal from "../../../components/Modal/rightModal";
import ModalComponent from "../../../components/Modal";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
// import {
//   getWalletBalanceSelector
// } from "../../../services/slices/vendor/balance";
import ReactPhoneInput from "react-phone-input-2";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export default function OrdersVendorDash() {
  const dispatch = useDispatch();
  const { loading, ordersVendor } = useSelector(getOrderVendorSelector);
  // const { balance } = useSelector(getWalletBalanceSelector);
  const [orderDetails, setOrderDetails] = useState();
  // const [isLoading, setIsLoading] = useState({
  //   loading: false,
  //   key: 0,
  // });
  const [loadingNow, setLoadingNow] = useState(false);
  const [weight, setWeight] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  const [searchRegion, setSearchRegion] = useState("");
  const [logisticsType, setLogisticsType] = useState("");
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    description: "",
    landmark: "",
  });
  const [transactionType, setTransactionType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [shipmentType, setShipmentType] = useState("");

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleChange(e) {
    setShipmentType(e.target.value);
  }

  function handleChangeWeight(e) {
    setWeight(e.target.value);
  }

  const showModal = (row) => {
    setOrderDetails(row);
    document.getElementById("showMod").click();
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  // const openPriceModal = (row) => {
  //   // document.getElementById("openModalBtn").click();
  //   if (balance < row?.amount) {
  //     toast("Please fund your wallet", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   } else {
  //     setIsLoading({ loading: true, key: row?.id });
  //     return makeAPICall({
  //       path: `/shipping/pay/${row?.id}`,
  //       method: "PATCH",
  //     })
  //       .then((res) => {
  //         setIsLoading({ loading: false, key: 0 });
  //         dispatch(getOrdersVendor({}));
  //         dispatch(getWalletBalance());
  //         toast("Payment Successful", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       })
  //       .catch((err) => {
  //         setIsLoading({ loading: false, key: 0 });
  //         toast(err.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       });
  //   }
  // };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.username,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: false,
      width: "200px",
    },
    {
      name: "Tracking Number",
      selector: (row) => row?.track,
      sortable: false,
      width: "200px",
    },
    {
      name: "Item Delivered",
      selector: (row) => row?.is_delivered,
      sortable: false,
      cell: (row) => {
        if (row.is_delivered) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Rider Assigned",
      selector: (row) => row?.id,
      sortable: false,
      cell: (row) => {
        if (
          (row?.is_delivered || row.is_delivered === false) &&
          row?.riderStatus.length > 0
        ) {
          return <p>Yes</p>;
        } else {
          return <p>No</p>;
        }
      },
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: false,
      cell: (row) => <p>₦{formatMoney(row?.amount)}</p>,
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "450px",
      cell: (row) => {
        if (row?.is_approved && row.amount) {
          return (
            <div className="d-flex justify-content-between">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginRight: "10px" }}
              >
                View More
              </button>
              <button type="submit" className="btn btn-primary" disabled={true}>
                Order Paid
              </button>
            </div>
          );
        } else if (!row?.is_approved && !row.amount) {
          return (
            <div className="d-flex justify-content-between">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginRight: "10px" }}
              >
                View More
              </button>
              <button type="submit" className="btn btn-danger" disabled={true}>
                Awaiting Order Confirmation
              </button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => showModal(row)}
                style={{ marginRight: "10px" }}
              >
                View More
              </button>
              {/* <button
                type="submit"
                className="btn btn-danger"
                onClick={() => openPriceModal(row)}
                style={{ marginRight: "10px" }}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Pay Order"}
              </button> */}
            </div>
          );
        }
      },
    },
  ];

  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  const handlePageChange = (page) => {
    dispatch(
      getOrdersVendor({
        params: { page: page },
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      getOrdersVendor({
        params: { page: page },
      })
    );
  };

  function handleSubmit() {
    // console.log(shipmentId, "test");
    setLoadingNow(true);
    const plus = "+";
    const phoneNumber = plus.concat(phoneState);
    const dataNow = {
      username: `${inputValues.first_name} ${inputValues.last_name})`,
      email: inputValues.email,
      phone: phoneNumber,
      address: inputValues.address,
      ship_type: shipmentType,
      landmark: inputValues.landmark,
      city: inputValues.city,
      state: region,
      country: country,
      weight: weight,
      description: inputValues.description,
      logistic_type: logisticsType,
    };
    return makeAPICall({
      path: "/shipping/order/",
      method: "POST",
      payload: dataNow,
    })
      .then((res) => {
        setLoadingNow(false);
        handleCloseModal();
        dispatch(getOrdersVendor({}));
        toast("You have created an order", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoadingNow(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handleSubmitSearch = () => {
    dispatch(
      getOrdersVendor({
        params: {
          email: email,
          username: name,
          phone: phone,
          ship_type: transactionType,
          country: searchCountry,
          state: searchRegion,
        },
      })
    );
    setName("");
    setEmail("");
    setTransactionType("");
    setSearchCountry("");
    setSearchRegion("");
    setPhone("");
  };


  return (
    <BackBone title="All Orders">
      <div>
        <div className="row">
          <div className="col-md-6"></div>
          {/* button to create order */}
          <div className="col-md-6 text-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              Create Order
            </button>
          </div>
        </div>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Ship Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={searchCountry}
                classes="selectDrop"
                onChange={(val) => setSearchCountry(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">State</label>
              <RegionDropdown
                country={searchCountry}
                classes="selectDrop"
                value={searchRegion}
                onChange={(val) => setSearchRegion(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    name ||
                    transactionType ||
                    email ||
                    phone ||
                    searchCountry ||
                    searchRegion
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={ordersVendor?.results}
              progressPending={loading}
              pagination
              title="Orders"
              paginationServer
              paginationTotalRows={ordersVendor?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>

        {/* hidden button to fire the drawer to show more order details*/}
        <button
          type="submit"
          className="btn btn-primary"
          id="showMod"
          data-bs-toggle="modal"
          data-bs-target="#modal-right"
          style={{ display: "none" }}
        >
          View More
        </button>

        {/* modal to create order */}
        <ModalComponent title="Create An Order">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Okon"
                      onChange={handleChangeInput}
                      value={inputValues.first_name}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Hamza"
                      onChange={handleChangeInput}
                      value={inputValues.last_name}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="e.g. example@mail.com"
                      onChange={handleChangeInput}
                      value={inputValues.email}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. No 1. Ijebu Ode Street"
                      onChange={handleChangeInput}
                      value={inputValues.address}
                      name="address"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Shipment Type</label>
                    <select
                      onChange={handleChange}
                      className="selectDrop"
                      // name="riderSelect"
                      value={shipmentType}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="delivery">Delivery</option>
                      <option value="pickup">Pick Up</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "formInput",
                      }}
                      value={phoneState}
                      onChange={handleOnChangePhoneInput}
                      country="ng"
                      placeholder="080212345678"
                      autoFormat={false}
                      enableClickOutside={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <CountryDropdown
                      value={country}
                      classes="selectDrop"
                      onChange={(val) => setCountry(val)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <RegionDropdown
                      country={country}
                      classes="selectDrop"
                      value={region}
                      onChange={(val) => setRegion(val)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Akoka"
                      onChange={handleChangeInput}
                      value={inputValues.city}
                      name="city"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. Pako bus Stop"
                      onChange={handleChangeInput}
                      value={inputValues.landmark}
                      name="landmark"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Item Weight</label>
                    <select
                      onChange={handleChangeWeight}
                      className="selectDrop"
                      // name="riderSelect"
                      value={weight}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="0.5kg">0.5kg</option>
                      <option value="1kg">1kg</option>
                      <option value="2kg">2kg</option>
                      <option value="3kg">3kg</option>
                      <option value="4kg">4kg</option>
                      <option value="5kg">5kg</option>
                      <option value="6kg">6kg</option>
                      <option value="7kg">7kg</option>
                      <option value="8kg">8kg</option>
                      <option value="9kg">9kg</option>
                      <option value="10kg">10kg</option>
                      <option value="above10kg">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Item Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. an iphone 13 pro max"
                      onChange={handleChangeInput}
                      value={inputValues.description}
                      name="description"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Logistics Type</label>
                    <select
                      onChange={(e) => setLogisticsType(e.target.value)}
                      className="selectDrop"
                      // name="riderSelect"
                      value={logisticsType}
                    >
                      <option disabled={true} value="">
                        --Choose an option--
                      </option>
                      <option value="bike">Bike</option>
                      <option value="van">Van</option>
                      <option value="warehouse">Drop-off</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  !(
                    weight &&
                    shipmentType &&
                    logisticsType &&
                    phoneState &&
                    country &&
                    region &&
                    inputValues.first_name &&
                    inputValues.last_name &&
                    inputValues.email &&
                    inputValues.city &&
                    inputValues.landmark &&
                    inputValues.address &&
                    inputValues.description
                  )
                }
              >
                {loadingNow ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Create Order
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
        {/* modal to create order end */}

        {/* right drawer to see order details begin */}
        <RightModal title={orderDetails?.username}>
          <div>
            <h1 style={{ fontSize: "18px" }}>Shipment Information</h1>
            <hr />
            <div>
              <h1 style={{ fontSize: "16px" }}>Shipment Number</h1>
              <p style={{ fontSize: "14px" }}>{orderDetails?.id}</p>
              <h1 style={{ fontSize: "16px" }}>Shipment Status</h1>
              <p style={{ fontSize: "14px" }}>
                {orderDetails?.riderStatus[0]?.status}
              </p>
              <h1 style={{ fontSize: "16px" }}>Date</h1>
              <p style={{ fontSize: "14px" }}>
                {moment(orderDetails?.riderStatus[0]?.updated_at).format("L")}
              </p>
            </div>
            <hr />
            <h1 style={{ fontSize: "18px" }}>Order Information</h1>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Address</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.address}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Shipment Type</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.ship_type}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Landmark</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.landmark}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>City</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.city}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>State</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.state}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Country</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.country}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Description</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.description}</p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Item Weight</h1>
                <p style={{ fontSize: "14px" }}>{orderDetails?.weight}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Delivered</h1>
                <p style={{ fontSize: "14px" }}>
                  {orderDetails?.is_delivered ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-md-6">
                <h1 style={{ fontSize: "16px" }}>Within Lagos?</h1>
                <p style={{ fontSize: "14px" }}>
                  {orderDetails?.is_managed ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </RightModal>
        {/* right drawer to see order details end */}
      </div>
    </BackBone>
  );
}
