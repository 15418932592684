import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";
import {
  getRiderSelector,
  getRiders,
} from "../../../services/slices/admin/riders";
import { getSchedules } from "../../../services/slices/admin/schedules";
import ModalComponent from "../../../components/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export default function AssignRiderAdmin() {
  const dispatch = useDispatch();
  const { riders } = useSelector(getRiderSelector);
  const [shipmentId, setShipmentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [riderSelect, setRiderSelect] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  // const [isReloaded, setIsReloaded] = useState(false);

  function assignRider(params = null) {
    // console.log(shipmentId, "test");
    setLoading(true);
    return makeAPICall({
      path: `/shipping/admin/paid/orders/`,
      method: "GET",
      params,
    })
      .then((res) => {
        setLoading(false);
        // console.log(res, 'orders');
        setOrders(res);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    assignRider();
  }, []);

  // useEffect(() => {
  //   window.location.href = window.location.href + '?refresh=true';
  // }, []);
  function openModal(row) {
    setShipmentId(row);
    document.getElementById("openModalNow").click();
  }

  function handleCloseModal() {
    document.getElementById("closeButton").click();
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.username,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      width: "300px",
    },
    {
      name: "Address",
      selector: (row) => row?.address,
      sortable: false,
      width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: false,
      width: "200px",
    },
    {
      name: "Tracking Number",
      selector: (row) => row?.track,
      sortable: false,
      width: "200px",
    },

    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "200px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => openModal(row?.id)}
            >
              Assign Rider
            </button>
          </div>
        );
      },
    },
  ];

  function handleChange(e) {
    setRiderSelect(e.target.value);
  }

  function handlePageChange(page) {
    assignRider({
      params: { page: page },
    });
  }

  async function handlePerRowsChange(newPerPage, page) {
    assignRider({
      params: { page: page },
    });
  }

  function handleSubmit() {
    // console.log(shipmentId, "test");
    setIsLoading(true);
    const dataNow = {
      shipment: shipmentId,
      rider: riderSelect,
    };
    return makeAPICall({
      path: "/rider/admin/book/rider/",
      method: "POST",
      payload: dataNow,
    })
      .then((res) => {
        setIsLoading(false);
        handleCloseModal();
        assignRider({});
        dispatch(getRiders({}));
        dispatch(getSchedules({}));
        setRiderSelect();
        toast("A rider has been assigned", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handleSubmitSearch = () => {
    assignRider({
      params: {
        email: email,
        username: name,
        phone: phone,
        ship_type: transactionType,
        country: country,
        state: region,
      },
    });
    setName("");
    setEmail("");
    setTransactionType("");
    setCountry("");
    setRegion("");
    setPhone("");
  };

  return (
    <AdminBackBone title="Assign Riders">
      <div>
        <button
          type="submit"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          id="openModalNow"
          style={{ display: "none" }}
        >
          Assign Rider Modal
        </button>
        <br />
        <div className="box-body bg-white">
          <div className="row">
            <div className="col-md-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Search by email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                style={{ height: "45px" }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Ship Type</label>
              <select
                onChange={(e) => setTransactionType(e.target.value)}
                className="selectDrop"
                // name="riderSelect"
                value={transactionType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={country}
                classes="selectDrop"
                onChange={(val) => setCountry(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">State</label>
              <RegionDropdown
                country={country}
                classes="selectDrop"
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">&nbsp;</label>
              <br />
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  !(
                    name ||
                    transactionType ||
                    email ||
                    phone ||
                    country ||
                    region
                  )
                }
                onClick={handleSubmitSearch}
              >
                <span>
                  <i className="ti-save-alt"></i> Search
                </span>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <DataTable
              columns={columns}
              data={orders?.results}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={orders?.count}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <ModalComponent title="Assign Rider">
          <div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="my-10">Select Rider</h5>
                  <select
                    onChange={handleChange}
                    className="selectDrop"
                    // name="riderSelect"
                    value={riderSelect}
                  >
                    <option disabled={true} value="">
                      --Choose an option--
                    </option>
                    {riders?.results?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.firstname}&nbsp; {item.lastname}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <button
                type="button"
                className="btn btn-warning me-1"
                onClick={handleCloseModal}
              >
                <i className="ti-trash"></i> Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!riderSelect}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  "loading"
                ) : (
                  <span>
                    <i className="ti-save-alt"></i> Save
                  </span>
                )}
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </AdminBackBone>
  );
}
