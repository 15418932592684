import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  hasErrors: null,
  users: {},
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = UserSlice.actions;

// A selector
export const getUserSelector = (state) => state.user;

// The reducer
export default UserSlice.reducer;

// api call action
export const getUsers = ({ params = null }) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/auth/list-users/",
    params,
    method: "GET",
  })
    .then((res) => {
      dispatch(getAppSuccess(res));
    })
    .catch((err) => {
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAppFailure(err));
    });
};
