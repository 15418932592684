import React from "react";
import "./footer.css";

import AioLogo from "../../assets/images/aioWhiteImg.png";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img src={AioLogo} alt="aio-logo" className="aio-logo" />
              <p>
                We will get your items delivered to its last mile destination
                with no hassles
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3">
              <span>Company</span>
              <ul>
                <li className="list-items">
                  <a href="/track" className="footer-list">
                    Track
                  </a>
                </li>
                <li className="list-items">
                  <a href="/services" className="footer-list">
                    Services
                  </a>
                </li>
                <li className="list-items">
                  <a href="/about" className="footer-list">
                    About
                  </a>
                </li>
                <li className="list-items">
                  <a href="/contact" className="footer-list">
                    Contact
                  </a>
                </li>
                {/* <li className="list-items">
                  <a href="/pricing" className="footer-list">
                    Pricing
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col-md-3">
              <span>Follow us</span>
              <ul className="d-flex gap-items-2">
                <li>
                  <a
                    href="https://web.facebook.com/aiologistics/?_rdc=1&_rdr"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-list"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/aio_logistics?lang=en"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-list"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/aiologistics/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-list"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/aiologistics/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-list"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center footer-text">
              Copyright &#169;{" "}
              <script>document.write(new Date().getFullYear())</script>{" "}
              <span>AIO Logistics,</span> All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
